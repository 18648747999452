import { ActionsPopover, ActionsPopoverItem } from "./actionsPopover"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { PopoverProps } from "@mui/material";
import { Interaction } from "../../types";
import { useModal } from "../../hooks/useModal";
import { ConfirmModal, ConfirmModalBody, ConfirmModalStrong, deleteAcceptText, deleteTitle } from "../modals/confirmModal";
import { useAppDispatch } from "../../app/hooks";
import { fetchDeleteInteraction } from "../../features/session/sessionSlice";
import { useNavigate } from "react-router-dom";
import { useSessionUrl } from "../../hooks/useUrl";


export const InteractionActionsPopover = ({ interaction, sessionId, anchorOrigin, transformOrigin, ...props }: PopoverProps & { interaction: Interaction, sessionId: string }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const sessionUrl = useSessionUrl()

    const deleteModal = useModal();

    const close = () => {
        props.onClose?.({}, 'escapeKeyDown');
    }

    const onRenameClicked = () => {
        console.log('Rename clicked')
        close();
    }

    const onShareClicked = () => {
        console.log('Share clicked')
        close();
    }

    const onDeleteClicked = () => {
        deleteModal.onClick();
        close();
    }

    const deleteInteraction = () => {
        dispatch(fetchDeleteInteraction({ sessionId: sessionId, interactionId: interaction.id }))
        navigate(sessionUrl || '/')
    }

    const passedProps = {
        ...props,
        anchorOrigin: anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'center',
        },
        transformOrigin: transformOrigin || {
            vertical: 'top',
            horizontal: 'center',
        }
    }

    const confirmModalBody = <>
        <ConfirmModalBody>Are you sure you want to delete the interaction <ConfirmModalStrong>{interaction.name}</ConfirmModalStrong>? This action cannot be reversed.</ConfirmModalBody>
    </>

    return <>
        <ConfirmModal open={deleteModal.open} onClose={deleteModal.onClose} onAccept={deleteInteraction}
            title={deleteTitle} body={confirmModalBody} acceptText={deleteAcceptText}
            icon={<DeleteOutlineOutlinedIcon />} />
        <ActionsPopover {...passedProps}>
            <ActionsPopoverItem disabled onClick={onRenameClicked} text="Rename" icon={<EditOutlinedIcon />} />
            <ActionsPopoverItem disabled onClick={onShareClicked} text="Share" icon={<ShareOutlinedIcon />} />
            <ActionsPopoverItem onClick={onDeleteClicked} text="Delete" color="error" icon={<DeleteOutlineOutlinedIcon color="error" />} />
        </ActionsPopover >
    </>
}
