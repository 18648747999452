import { Box, Card, CardActionArea, CardHeader, CardMedia, Typography, styled } from "@mui/material"
import { Session } from "../../types"
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonIcon from '@mui/icons-material/Person';
import LinkIcon from '@mui/icons-material/Link';
import { Link } from "react-router-dom";
import { MembersAvatarGroup, getMemberInfoById } from "../../members";
import LinesEllipsis from "react-lines-ellipsis";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { getSessionImage, getSessionLastUpdated } from "../../sessionUtils";
import { dateTimeStringToReadableTimeSince } from "../../time";


const RoundedCardMedia = styled(CardMedia)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    height: '100px',
}));


const CardStatComponent = ({ icon, value }: { icon: React.ReactNode, value: string }) => {
    return <Box className="card-stat-small">
        <Typography variant="caption" color="text.secondary">{icon}</Typography>
        <Typography variant="caption" color="text.secondary">{value}</Typography>
    </Box>
}

const getSessionUrl = (session: Session, linkToPublished?: boolean) => {
    return linkToPublished && session.published_session ? `/p/${session.published_session.id}` : `/${session.id}`;
}

export const SessionCardLargeComponent = ({ session, linkToPublished }: { session: Session, linkToPublished?: boolean }) => {

    const { user } = useAuthenticator(context => [context.user])
    const userIsMember = session.members.some(member => member.user.id === user?.username);

    const memberInfo = getMemberInfoById(session.created_by, session.members);
    const name = session.published_session ? memberInfo?.username : memberInfo?.displayName;

    const lastUpdated = getSessionLastUpdated(session);
    const lastUpdatedString = dateTimeStringToReadableTimeSince(lastUpdated)

    const sessionUrl = getSessionUrl(session, linkToPublished);
    const n_contents = session.contents.length;
    const n_interactions = session.statistics.n_interactions;
    const n_favorites = session.statistics.n_favorites;


    return <Card sx={{ width: '220px', height: '232px' }}>
        <CardActionArea component={Link} to={sessionUrl}>
            <Box className="session-card-large-contents">
                <RoundedCardMedia image={getSessionImage(session)}></RoundedCardMedia>
                <Typography variant="body2" color="text.secondary" sx={{ paddingTop: '4px' }}>{name}</Typography>
                <Typography variant="h3" color="text.primary" sx={{ flex: 1 }}>
                    <LinesEllipsis
                        text={session.name}
                        maxLine='2'
                        ellipsis='...'
                        trimRight
                        basedOn='letters'
                    />
                </Typography>
                {
                    userIsMember ?
                        <Box className="session-card-footer">
                            <MembersAvatarGroup members={session.members} max={5} avatarProps={{ sx: { width: '20px', height: '20px', fontSize: '0.5em' } }} />
                            <Typography variant="body2" color="text.secondary">Updated {lastUpdatedString} ago</Typography>
                        </Box>
                        :
                        <Box className="card-stats-small" color="text.secondary">
                            <CardStatComponent icon={<FavoriteIcon fontSize="inherit" />} value={`${n_favorites}`} />
                            <CardStatComponent icon={<PersonIcon fontSize="inherit" />} value={`${n_interactions}`} />
                            <CardStatComponent icon={<LinkIcon fontSize="inherit" />} value={`${n_contents}`} />
                        </Box>
                }
            </Box>
        </CardActionArea>
    </Card>
}

export const SessionCardSmallComponent = ({ session }: { session: Session }) => {
    const avatar = <CardMedia sx={{ width: '50px', height: '50px' }} component="img" image={getSessionImage(session)}></CardMedia>
    const n_contents = session.contents.length;
    const n_interactions = session.statistics.n_interactions;
    const n_favorites = session.statistics.n_favorites;
    const subheader = <Box className="card-stats-small">
        <CardStatComponent icon={<FavoriteIcon fontSize="inherit" />} value={`${n_favorites}`} />
        <CardStatComponent icon={<PersonIcon fontSize="inherit" />} value={`${n_interactions}`} />
        <CardStatComponent icon={<LinkIcon fontSize="inherit" />} value={`${n_contents}`} />
    </Box>

    const sessionUrl = getSessionUrl(session, true);

    return <Card sx={{ background: 'transparent', border: '0', boxShadow: '0' }}>
        <CardActionArea component={Link} to={sessionUrl}>
            <CardHeader sx={{ padding: '0' }} title={session.name} subheader={subheader} avatar={avatar}></CardHeader>
        </CardActionArea>
    </Card>
}

export const PublicSessionCardSmallComponent = ({ session }: { session: Session }) => {
    const creatorInfo = getMemberInfoById(session.created_by, session.members);
    const sessionUrl = getSessionUrl(session, true);

    const n_contents = session.contents.length;
    const n_interactions = session.statistics.n_interactions;
    const n_favorites = session.statistics.n_favorites;

    return <Card>
        <CardActionArea component={Link} to={sessionUrl} sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '16px',
        }}>
            <Box>
                <CardMedia sx={{ width: '66px', height: '66px' }} component="img" image={getSessionImage(session)}></CardMedia>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <Typography variant="body2" color="text.secondary" >{creatorInfo?.displayName}</Typography>
                <Typography variant="h4" noWrap>{session.name}</Typography>
                <Box className="card-stats-small">
                    <CardStatComponent icon={<FavoriteIcon fontSize="inherit" />} value={`${n_favorites}`} />
                    <CardStatComponent icon={<PersonIcon fontSize="inherit" />} value={`${n_interactions}`} />
                    <CardStatComponent icon={<LinkIcon fontSize="inherit" />} value={`${n_contents}`} />
                </Box>
            </Box>
        </CardActionArea>
    </Card>
}