import type { AmplifyUser } from '@aws-amplify/ui';

export type GuestSessionState = {
    first_name: string
    last_name: string
    username: string
    id: string
}

export type MemberRole = "ADMIN" | "CONTRIBUTOR" | "VIEWER" | "VISITOR"

export interface User {
    id: string
    email: string
    joined_date?: string
    verified: boolean
    image?: string
    first_name?: string
    last_name?: string
    username?: string
}

export const userFromAmplifyUser = (amplifyUser: AmplifyUser): User => {
    if (!amplifyUser.attributes) {
        throw new Error("Amplify user has no attributes")
    }
    return {
        id: amplifyUser.attributes.sub,
        email: amplifyUser.attributes.email,
        verified: !!amplifyUser.attributes.email_verified,
        image: amplifyUser.attributes.picture,
        first_name: amplifyUser.attributes.given_name,
        last_name: amplifyUser.attributes.family_name,
        username: amplifyUser.attributes.nickname
    }
}


export interface Member {
    role: MemberRole
    user: User
}

export interface IdResponse {
    id: string
}

export interface InteractionEvent {
    event_type: "QueryEvent" | "TextResponseEvent" | "TextResponseEventChunk" | "ToolResponseEvent" | "ToolResponseEventChunk" | "MessageEvent" | "AddMemberEvent"
    id: string
    datetime: string
    user_id?: string
    query?: string
    query_id?: string
    response?: string
    end_time?: string
    parent_id?: string
    chunk?: string
    message?: string
    added_member_id?: string
    is_generating?: boolean
    tool_id?: string
    tool_name?: string
    references?: string[][]
}

export type InteractionType = "QUESTION_ANSWERING" | "CHAT" | "TEXT_GENERATION" | "MAP_GENERATION" | "IMAGE_GENERATION"

export interface Interaction {
    id: string
    name: string
    events: InteractionEvent[]
    members: string[]
    created_time: string
    created_by: string
    type: InteractionType
    is_session_public: boolean
}

export type SessionActionType = "AddInteractionAction" | "SendMessageAction" | "AddContentAction" | "ProcessContentAction" | "SummarizeContentAction" | "ErrorContentAction" | "AddMemberAction" | "LinkSessionAction" | "DeleteContentAction" | "RemoveMemberAction" | "UnlinkSessionAction"

export interface SessionAction {
    user_id: string
    datetime: string
    action_type: SessionActionType
    added_content_id?: string
    processed_content_id?: string
    summarized_content_id?: string
    errored_content_id?: string
    deleted_content_id?: string
    added_member_id?: string
    removed_member_id?: string
    role?: MemberRole
    linked_session_id?: string
    unlinked_session_id?: string
    message?: string
    interaction_id?: string
}

export interface SessionStatistics {
    n_interactions: number
    references_per_content: { [key: string]: number }
    n_favorites: number
}

export interface Session {
    id: string
    name: string
    created_by: string
    created_time: string
    linked_sessions: string[]
    members: Member[]
    state: "ACTIVE" | "CLOSED" | "INACTIVE" | "DELETED"
    interactions: Interaction[]
    contents: Content[]
    log: SessionAction[]
    statistics: SessionStatistics
    published_session?: PublishedSession
    description?: string
    is_favorite: boolean
    is_link_public: boolean
    is_hub_session?: boolean
    base_prompt?: string
}

export interface TextChunk {
    text: string
    start_index: number
    end_index: number
    id: string
    start_byte_index: number
    end_byte_index: number
}

export interface PublishedSessionSettings {
    add_content_min_role: MemberRole
    share_interaction_min_role: MemberRole
}

export const defaultPublishedSessionSettings: PublishedSessionSettings = {
    add_content_min_role: "ADMIN",
    share_interaction_min_role: "VIEWER"
}

export type PublishedSessionState = "ACTIVE" | "INACTIVE" | "UNPUBLISHED"

export interface PublishedSession {
    id: string
    name: string
    description: string
    published_time: string
    updated_time: string
    settings: PublishedSessionSettings
    state: PublishedSessionState
}

export type ContentState = "INGESTED" | "EMBEDDED" | "SUMMARIZED" | "ERROR"

export interface Content {
    id: string
    name: string
    created_by: string
    created_time: string
    source: string
    state: ContentState
    is_primary: boolean
    tags: { [key: string]: string }
}

export interface ContentFile {
    type: "TEXT" | "HTML" | "PDF" | "WORD" | "UNKNOWN"
    name: string
    data: string
    id: string
}

export interface TextContent extends Content {
    file: ContentFile
    text_chunks: string[]
}

export interface Invitation {
    email: string
    invited_by: string
    duration: number
    created_datetime: string
    id: string
    claimed_datetime?: string
    claimed_by?: string
}

export interface PaymentUrlResponse {
    url: string
}

export interface InvitationIdResponse {
    invitation_id: string
}

export interface SizeResponse {
    size: number
}