import {
    PaletteColor,
    PaletteColorOptions,
    experimental_extendTheme as extendTheme,
} from '@mui/material/styles';

import "@mui/material/styles/createPalette";

declare module '@mui/material/styles' {
    interface PaletteOptions {
        paper: PaletteColorOptions;
        contrast: PaletteColorOptions;
        border: PaletteColorOptions;
    }
    interface Palette {
        paper: PaletteColor;
        contrast: PaletteColor;
        border: PaletteColor;
    }
}

declare module "@mui/material/styles/createPalette" {
    export interface TypeBackground {
        paperSecondary: string;
        paperDisabled: string;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        paper: true;
        contrast: true;
    }
}

declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsSizeOverrides {
        tiny: true;
    }
}

export const customTheme = extendTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 960,
            lg: 1280,
            xl: 1920,
        }
    },
    shape: {
        borderRadius: 16
    },
    spacing: 8,
    typography: {
        fontFamily: 'Inter, sans-serif',
        button: {
            textTransform: 'none'
        },
        caption: {
            fontSize: '0.75rem',
            lineHeight: 'normal'
        },
        h1: {
            fontSize: '2rem',
            lineHeight: '2.4rem',
            fontWeight: 600
        },
        h2: {
            fontSize: '1.5rem',
            lineHeight: '1.5rem',
            fontWeight: 600
        },
        h3: {
            fontSize: '1rem',
            lineHeight: '1.3rem',
            fontWeight: 600
        },
        h4: {
            fontSize: '1rem',
            lineHeight: '1.3rem',
            fontWeight: 400
        },
        body1: {
            // TODO: This has to be set to 1 rem to avoid textfield issues
            fontSize: '0.875rem',
            lineHeight: '1.3rem',
            fontWeight: 400
        },
        body2: {
            fontSize: '0.75rem',
            lineHeight: '1.3rem',
            fontWeight: 400
        },
    },
    colorSchemes: {
        dark: {
            palette: {
                primary: {
                    main: '#FFFFFF',
                },
                secondary: {
                    main: '#000000',
                },
                text: {
                    primary: '#FFFFFF',
                    secondary: 'rgba(239, 239, 239, 0.60)',
                },
                background: {
                    default: 'rgba(15, 16, 18, 1)',
                    paper: 'rgba(28, 30, 33, 1)',
                    paperSecondary: 'rgba(39, 40, 45, 1)',
                    paperDisabled: 'rgba(239, 239, 239, 0.1)'
                },
                paper: {
                    main: 'rgba(28, 30, 33, 1)',
                    contrastText: '#FFFFFF',
                },
                contrast: {
                    main: 'rgba(239, 239, 239, 1)',
                    contrastText: 'rgba(15, 16, 18, 1)',
                    dark: 'rgba(239, 239, 239, 0.6)',
                },
                border: {
                    main: 'rgba(44, 45, 50, 1)'
                }
            }
        },
        light: {
            palette: {
                primary: {
                    main: '#000000',
                },
                secondary: {
                    main: '#FFFFFF',
                },
                paper: {
                    main: 'rgba(255, 255, 255, 1)',
                    contrastText: '#000000',
                },
                background: {
                    default: 'rgba(250, 249, 249, 1)',
                    paper: 'rgba(255, 255, 255, 1)',
                    paperSecondary: 'rgba(236, 235, 235, 1)',
                    paperDisabled: 'rgba(21, 20, 20, 0.1)'
                },
                contrast: {
                    main: 'rgba(48, 47, 46, 1)',
                    contrastText: 'rgba(250, 249, 249, 1)',
                    dark: 'rgba(21, 20, 20, 0.6)',
                },
                border: {
                    main: 'rgba(232, 232, 232, 1)'
                }
            }
        }
    }
    ,
    components: {
        MuiCard: {
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    gap: theme.spacing(1.5),
                    padding: theme.spacing(2),
                    margin: '1px',
                    backgroundImage: 'none',
                    '&:hover': {
                        backgroundColor: ownerState.className?.includes("no-hover") ? theme.palette.background.paper : theme.palette.action.hover,
                    },
                    // @ts-ignore
                    border: `1px solid ${theme.palette.border.main}`,
                }),
            },
        },
        MuiCardActionArea: {
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    height: '100%',
                    '& .MuiCardActionArea-focusHighlight': {
                        backgroundColor: 'transparent'
                    }
                }),
            },
        },
        MuiButton: {
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    borderWidth: '1px',
                    gap: theme.spacing(1),
                }),
                sizeLarge: ({ theme, ownerState }) => ({
                    padding: theme.spacing(1.5, 6),
                }),
            },
        },
        // TODO: Fix this 
        MuiInputBase: {
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    fontSize: '1rem',
                    '& input:-webkit-autofill': {
                        WebkitBoxShadow: `0 0 0 1000px rgba(0,0,0,0.2) inset !important`,
                        WebkitTextFillColor: 'black !important',
                    },
                }),
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    backgroundColor: theme.palette.background.paper,
                    '&.Mui-disabled': {
                        backgroundColor: theme.palette.background.paperDisabled,
                        'fieldset': {
                            // @ts-ignore
                            border: 0,
                        }
                    },
                    'fieldset': {
                        // @ts-ignore
                        border: `1px solid ${theme.palette.border.main}`,
                    }
                }),
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    minWidth: theme.spacing(4),
                }),
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    backgroundColor: theme.palette.background.default,
                }),
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: ({ theme, ownerState }) => ({
                    backgroundImage: 'none',
                }),
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: ({ theme, ownerState }) => {
                    if (ownerState.fontSize === 'tiny') {
                        return {
                            fontSize: '1rem',
                        }
                    }
                }
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    backgroundColor: 'rgba(37, 37, 37, 0.6)'
                }),
                invisible: {
                    backgroundColor: 'rgba(37, 37, 37, 0.2)'
                },
            },
        }
    }
});