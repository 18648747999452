import { Box } from '@mui/material';
import { Content, Interaction } from '../../types';
import { SourceCardSmall } from '../cards/sourceCard';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchAndAddContent, selectCurrentContentReferences } from '../../features/session/sessionSlice';
import { useEffect, useMemo } from 'react';

interface ReferencedContent {
    content: Content,
    chunkId?: string
}

interface Reference {
    contentId: string,
    chunkId?: string
}

const toReference = (raw_reference: string[]): Reference => {
    const [contentId, chunkId] = raw_reference;
    return { contentId, chunkId };
}

const getContentById = (contents: Content[], contentId: string) => {
    return contents.find(content => content.id === contentId);
}

const filterUndefined = (value: any | undefined) => {
    return value !== undefined;
}

const stringOrArrayToArray = (value: undefined | string | string[]): string[] => {
    if (!value) {
        return [];
    }
    if (Array.isArray(value)) {
        return value;
    }
    return [value];
}

const getKey = (contentId: string, chunkId?: string) => {
    // return `${contentId}-${chunkId || ""}`
    return `${contentId}`
}

export const InteractionContents = ({ interaction, contents, sessionId }: { interaction: Interaction, contents: Content[], sessionId: string }) => {

    const dispatch = useAppDispatch();
    const events = interaction.events;
    const initialRefs = events.map(event => event.references).flat().filter(filterUndefined).map(stringOrArrayToArray).filter(reference => reference.length > 0);
    const references = initialRefs.map(toReference).filter(reference => reference !== undefined);

    useEffect(() => {
        references.forEach(reference => {
            if (!reference || !reference.contentId) {
                return
            }

            const content = getContentById(contents, reference.contentId)

            if (!content) {
                dispatch(fetchAndAddContent({ sessionId, contentId: reference.contentId }))
                return
            }
        })
    }, [contents, dispatch, references, sessionId])

    const referencedContents = useMemo(() => {
        const _referencedContents: ReferencedContent[] = [];

        const seenKeys = new Set<string>();

        references.forEach(reference => {
            if (!reference || !reference.contentId) {
                return
            }

            const content = getContentById(contents, reference.contentId)

            if (!content) {
                return
            }

            const key = getKey(reference.contentId, reference.chunkId)
            if (seenKeys.has(key)) {
                return
            }
            _referencedContents.push({ content, chunkId: reference.chunkId })
            seenKeys.add(key)
        })
        return _referencedContents;
    }
        , [contents, references]);

    const currentContentReferences = useAppSelector(selectCurrentContentReferences);
    const selectedContent = currentContentReferences.map(ref => ref.contentId);

    return <Box className="sources-list">
        {
            referencedContents.map(referencedContent => <SourceCardSmall isCurrentReference={selectedContent.includes(referencedContent.content.id)} key={getKey(referencedContent.content.id, referencedContent.chunkId)} content={referencedContent.content} />)
        }
    </Box>;

};
