import { Socket } from "../socket"
import { Middleware } from "@reduxjs/toolkit";
import { addInteractionEvent } from "../features/session/sessionSlice";
import { getInteractionSocketConfig } from "../api";

export const getInteractionSocketMiddleware = (socket: Socket) => {
  const interactionSocketMiddleware: Middleware = (params) => (next) => async (action) => {
    const { dispatch } = params
    // @ts-ignore
    const { type, sessionId, interactionId } = action

    const onMessage = (message: any) => {
      const data = JSON.parse(message.data)['event']
      const payload = { sessionId: sessionId, interactionId: interactionId, event: data }
      dispatch(addInteractionEvent(payload));
    }

    switch (type) {
      case 'interactionSocket/connect':
        const getConfig = async () => await getInteractionSocketConfig(sessionId, interactionId)
        await socket.connect(getConfig, interactionId, onMessage)
        break

      case 'interactionSocket/disconnect':
        socket.disconnect()
        break

      default:
        break
    }

    return next(action)
  }

  return interactionSocketMiddleware;
}