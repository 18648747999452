import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import LoginPage from './pages/login';
import 'github-markdown-css'
import {
  Experimental_CssVarsProvider as CssVarsProvider,
} from '@mui/material/styles';
import { RequireAuth } from './features/common/requireAuth';
import './App.css';
import useNotifier from './features/notifications/useNotifier';
import { RequireAdmin } from './features/common/requireAdmin';
import { RequireGuest } from './features/common/requireGuest';
import { AdminPage } from './pages/admin';
import { InvitedSignup } from './pages/invitedSignup';
import { VerifyPage } from './pages/verify';
import { GuestErrorPage } from './pages/guestError';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { HomePage } from './pages/home';
import { customTheme } from './theme';
import { SessionPage } from './pages/session';
import { InteractionPage } from './pages/interaction';
import { SourcesPage } from './pages/sources';
import { SearchPage } from './pages/search';
import { HelpPage } from './pages/help';
import { HubPage } from './pages/hub';
import { LegalPage } from './pages/legal';
import { PaymentSuccessPage } from './pages/paymentSuccess';
import { PaymentFailurePage } from './pages/paymentFailure';
import { SignupPage } from './pages/signup';
import { PremiumPage } from './pages/premium';
import * as Sentry from "@sentry/react";
import { TermsOfService } from './legal/termsOfService';
import { PrivacyPolicy } from './legal/privacyPolicy';

import { Hub } from 'aws-amplify';
import { useEffect } from 'react';
import { deleteGuestUserIfExists } from './guest';

export const production = process.env.NODE_ENV !== "development"
const enable_sentry = process.env.REACT_APP_SENTRY_ENABLED !== undefined
export const manageSubscriptionUrl = process.env.REACT_APP_STRIPE_MANAGE_SUBSCRIPTION_URL || ""
export const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
export const space_prompt_enabled = process.env.REACT_APP_FEATURE_SPACE_PROMPT_ENABLED === "True"

export const feedback = Sentry.feedbackIntegration({
  colorScheme: "system",
  buttonLabel: "Send feedback",
  submitButtonLabel: "Submit feedback",
  formTitle: "Feedback",
  successMessageText: "Thanks for your feedback!",
  showName: false,
  isNameRequired: false,
  messagePlaceholder: "What feedback do you have?",
  showBranding: false,
  autoInject: false,
  showEmail: false,
  isEmailRequired: false,
});

if (production && enable_sentry) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: "alpha-brua-webapp@0.2.0",
    integrations: [
      feedback,
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: process.env.REACT_APP_DEPLOYMENT,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/alpha.brua\.io/],
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

function App() {

  useEffect(() => {
    return Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        deleteGuestUserIfExists();
      }
    });
  }, []);

  useNotifier();
  return (
    <div className="App">
      <Authenticator.Provider>
        <Router>
          <CssVarsProvider theme={customTheme} defaultMode='dark'>
            <CssBaseline />
            <Routes>
              <Route path="/" element={<RequireGuest><HomePage /></RequireGuest>} />
              <Route path="/paymentSuccess" element={<PaymentSuccessPage />} />
              <Route path="/paymentFailure" element={<PaymentFailurePage />} />
              <Route path="/premium" element={<PremiumPage />} />
              <Route path="/:sessionId" element={<RequireGuest><SessionPage /></RequireGuest>} />
              <Route path="/:sessionId/info" element={<RequireGuest><SessionPage /></RequireGuest>} />
              <Route path="/:sessionId/sources" element={<RequireGuest><SourcesPage /></RequireGuest>} />
              <Route path="/:sessionId/sources/:sourceId" element={<RequireGuest><SourcesPage /></RequireGuest>} />
              <Route path="/:sessionId/:interactionId" element={<RequireGuest><InteractionPage /></RequireGuest>} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/search" element={<RequireGuest><SearchPage /></RequireGuest>} />
              <Route path="/help" element={<RequireGuest><HelpPage /></RequireGuest>} />
              <Route path="/p/:username/:sessionName" element={<RequireGuest><SessionPage /></RequireGuest>} />
              <Route path="/p/:username/:sessionName/info" element={<RequireGuest><SessionPage /></RequireGuest>} />
              <Route path="/p/:username/:sessionName/sources" element={<RequireGuest><SourcesPage /></RequireGuest>} />
              <Route path="/p/:username/:sessionName/sources/:sourceId" element={<RequireGuest><SourcesPage /></RequireGuest>} />
              <Route path="/p/:username/:sessionName/:interactionId" element={<RequireGuest><InteractionPage /></RequireGuest>} />
              <Route path="/hub" element={<RequireAuth><HubPage /></RequireAuth>} />
              <Route path="/admin" element={<RequireAdmin><AdminPage /></RequireAdmin>} />
              <Route path="/betaSignup/:inviteId" element={<InvitedSignup />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/verify" element={<RequireAuth allowUnverified><VerifyPage /></RequireAuth>} />
              <Route path="/sorry" element={<RequireGuest><GuestErrorPage /></RequireGuest>} />
              <Route path="/termsOfService" element={<LegalPage><TermsOfService /> </LegalPage>} />
              <Route path="/privacyPolicy" element={<LegalPage><PrivacyPolicy /> </LegalPage>} />
            </Routes>
          </CssVarsProvider>
        </Router>
      </Authenticator.Provider>
    </div>
  );
}

export default App;
