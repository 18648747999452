import { Socket } from "../socket"
import { Middleware } from "@reduxjs/toolkit";
import { addSessionAction, fetchAndAddContent } from "../features/session/sessionSlice";
import { getSessionSocketConfig } from "../api";

export const getSessionSocketMiddleware = (socket: Socket) => {
  const sessionSocketMiddleware: Middleware = (params) => (next) => async (action) => {
    const { dispatch } = params
    // @ts-ignore
    const { type, payload } = action;
    const sessionId = payload?.sessionId

    const onMessage = (event: any) => {
      const data = JSON.parse(event.data)['action']
      const payload = { sessionId: sessionId, action: data }
      if (data.action_type === 'UpdateContentAction') {
        // @ts-ignore
        dispatch(fetchAndAddContent({ sessionId: sessionId, contentId: data.updated_content_id }))
      }
      dispatch(addSessionAction(payload));
    }

    switch (type) {
      case 'session/socketConnect':
        const getConfig = async () => await getSessionSocketConfig(sessionId)
        await socket.connect(getConfig, sessionId, onMessage)
        break

      case 'session/socketDisconnect':
        socket.disconnect()
        break

      default:
        break
    }

    return next(action)
  }

  return sessionSocketMiddleware;
}