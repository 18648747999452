import React from 'react';
import { Session, Interaction } from '../../types';
import { InteractionComponentInput } from './interactionComponentInput';
import { QaEventsContainer } from './qaEventsContainer';


export const QAInteractionComponent = ({ interaction, session }: { interaction: Interaction, session: Session }) => {
    const members = session.members;

    if (!interaction) {
        return <></>
    }
    return <>
        {/* <InteractionEventsContainer events={interaction.events} members={members} /> */}
        <QaEventsContainer events={interaction.events} members={members} sessionId={session.id} interactionId={interaction.id} />
        <InteractionComponentInput interaction={interaction} session={session} />
    </>
}