import { Auth } from "aws-amplify";
import axios, { InternalAxiosRequestConfig } from "axios";
import { Content, MemberRole, Session, Member, PublishedSessionSettings, TextChunk, Invitation, IdResponse, PaymentUrlResponse, InvitationIdResponse, SizeResponse } from "./types";
import { SocketConfig } from "./socket";
import { isAuthenticated, isGuest } from "./auth";

const production = process.env.NODE_ENV !== "development"

const recaptcha_key = process.env.REACT_APP_RECAPTCHA_KEY || "";

let api_hostname;
let api_url: string;
let api_websocket_domain: string;
let api_websocket_protocol: string;

if (production) {
    api_hostname = ""
    const api_root_url = "/api/v1"
    api_url = api_root_url
    const loc = window.location
    api_websocket_protocol = 'wss'
    api_websocket_domain = `${loc.host}${api_root_url}`
} else {
    api_hostname = window.location.hostname
    const api_port = 8000
    api_url = `http://${api_hostname}:${api_port}`
    api_websocket_protocol = 'ws'
    api_websocket_domain = `${api_hostname}:${api_port}`
}

export interface SessionId {
    id: string
}

export interface UserId {
    id: string
}

export const getBearerToken = async () => {
    try {
        const session = await Auth.currentSession();
        return session.getIdToken().getJwtToken();
    } catch (e) {
        return undefined;
    }
}

export const getInteractionSocketConfig = async (sessionId: string, interactionId: string): Promise<SocketConfig> => {
    const token = await getBearerToken();
    const _path = `/sessions/${sessionId}/interactions/${interactionId}/ws`;
    const guestPrefix = await isGuest() ? "/guest" : "";
    const path = `${guestPrefix}${_path}`;
    return new SocketConfig(api_websocket_protocol, api_websocket_domain, path, token);
}

export const getSessionSocketConfig = async (sessionId: string): Promise<SocketConfig> => {
    const token = await getBearerToken();
    const _path = `/sessions/${sessionId}/ws`;
    const guestPrefix = await isGuest() ? "/guest" : "";
    const path = `${guestPrefix}${_path}`;
    return new SocketConfig(api_websocket_protocol, api_websocket_domain, path, token);
}

const axiosRequestInterceptor = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {

    const token = await getBearerToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}

const client = axios.create({
    baseURL: api_url
});

const publicClient = axios.create({
    baseURL: api_url
});

const guestClient = axios.create({
    baseURL: `${api_url}/guest`
});

const getRecaptchaToken = async () => {
    return new Promise<string>((resolve, reject) => {
        grecaptcha.enterprise.ready(async () => {
            try {
                const token = await grecaptcha.enterprise.execute(recaptcha_key, { action: 'SUBMIT' });
                resolve(token);
            } catch (e) {
                reject(e);
            }
        });
    });
}

const getRecaptchaHeaders = async () => {
    const guest = await isGuest();
    const headers = guest ? { "Recaptcha-Token": await getRecaptchaToken() } : undefined;
    return headers;
}

const getRoleBasedClient = async () => {
    if (await isAuthenticated()) {
        return client;
    } else if (await isGuest()) {
        return guestClient;
    }

    throw new Error("User is not authenticated");
}

client.interceptors.request.use(axiosRequestInterceptor, e => Promise.reject(e));

export async function getSessions() {
    return client.get<Session[]>("/sessions");
}

export async function createSession(name: string, description?: string) {
    return client.put<Session>("/sessions", null, { params: { "name": name, "description": description } });
}

export async function updateSession(sessionId: string, params: { name?: string, description?: string, is_link_public?: boolean, base_prompt?: string }) {
    return client.patch<Session>(`/sessions/${sessionId}`, params);
}

export async function deleteSession(sessionId: string) {
    return client.delete<SessionId>(`/sessions/${sessionId}`);
}

export async function getSession(sessionId: string) {
    const _client = await getRoleBasedClient();
    return _client.get<Session>(`/sessions/${sessionId}`, { withCredentials: true });
}

export async function addSessionMember(sessionId: string, userEmail: string, role: MemberRole) {
    return client.post<Member>(`/sessions/${sessionId}/members`, null, { params: { "role": role, "user_email": userEmail } });
}

export async function getSessionMember(sessionId: string, memberId: string) {
    return client.get<Member>(`/sessions/${sessionId}/members/${memberId}`);
}

export async function removeSessionMember(sessionId: string, userEmail: string) {
    return client.delete<UserId>(`/sessions/${sessionId}/members`, { params: { "user_email": userEmail } });
}

export async function addInteractionMember(sessionId: string, interactionId: string, userId: string) {
    return client.post(`/sessions/${sessionId}/interactions/${interactionId}/members`, null, { params: { "added_member_id": userId } });
}

export async function createInteraction(sessionId: string, name: string) {
    return client.post<string>(`/sessions/${sessionId}/interactions`, null, { params: { "name": name } });
}

export async function deleteInteraction(sessionId: string, interactionId: string) {
    return client.delete<void>(`/sessions/${sessionId}/interactions/${interactionId}`);
}

export async function renameInteraction(sessionId: string, interactionId: string, interactionName: string) {
    return client.put<void>(`/sessions/${sessionId}/interactions/${interactionId}`, { params: { "name": interactionName } });
}

export async function linkSession(sessionId: string, linkedSessionId: string) {
    return client.put(`/sessions/${sessionId}/linkedSessions/${linkedSessionId}`);
}

export async function linkPublishedSession(sessionId: string, username: string, sessionName: string) {
    return client.put(`/sessions/${sessionId}/linkedSessions/${username}/${sessionName}`);
}

export async function unlinkSession(sessionId: string, linkedSessionId: string) {
    return client.delete(`/sessions/${sessionId}/linkedSessions/${linkedSessionId}`);
}

export async function query(sessionId: string, interactionId: string, query: string) {
    const _client = await getRoleBasedClient();
    const headers = await getRecaptchaHeaders();
    return _client.post(`/sessions/${sessionId}/interactions/${interactionId}`, null, { withCredentials: true, params: { "query": query }, headers: headers });
}

export async function queryWithoutInteraction(sessionId: string, query: string) {
    const _client = await getRoleBasedClient();
    const headers = await getRecaptchaHeaders();
    return _client.post<IdResponse>(`/sessions/${sessionId}`, null, { withCredentials: true, params: { "query": query }, headers: headers });
}

export async function message(sessionId: string, message: string) {
    return client.post(`/sessions/${sessionId}/messages`, null, { params: { "message": message } });
}

export async function uploadFile(sessionId: string, file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return client.post(`/sessions/${sessionId}/content`, formData, { headers: { "Content-Type": file.type } });
}

export async function uploadUrl(sessionId: string, url: string) {
    return client.post(`/sessions/${sessionId}/content`, null, { params: { "url": url } });
}

export async function removeContent(sessionId: string, contentId: string) {
    return client.delete(`/sessions/${sessionId}/content/${contentId}`);
}

export async function getContent(sessionId: string, contentId: string) {
    return client.get<Content>(`/sessions/${sessionId}/content/${contentId}`);
}

export async function updateContent(sessionId: string, contentId: string, is_primary: boolean) {
    return client.patch<void>(`/sessions/${sessionId}/content/${contentId}`, { "is_primary": is_primary });
}

export async function getContentChunk(sessionId: string, contentId: string, chunkId: string) {
    return client.get<TextChunk>(`/sessions/${sessionId}/content/${contentId}/chunks`, { params: { "chunk_id": chunkId } });
}

export async function getPublishedSession(publishedSessionId: string) {
    const _client = await getRoleBasedClient();
    return _client.get<Session>(`/published_session/${publishedSessionId}`, { withCredentials: true });
}
export async function addInteractionEventFeedback(sessionId: string, interactionId: string, eventId: string, feedback: string[]) {
    const _client = await getRoleBasedClient();
    return _client.post<void>(`/sessions/${sessionId}/interactions/${interactionId}/event/${eventId}/feedback`, { feedback: feedback }, { withCredentials: true });
}

export async function publishSession(sessionId: string, name: string, description: string, settings: PublishedSessionSettings) {
    return client.post<string>(`/sessions/${sessionId}/publish`, { name: name, description: description, settings: settings });
}

export async function getHubSessions() {
    const _client = await getRoleBasedClient();
    return _client.get<Session[]>(`/hub/sessions`, { withCredentials: true });
}

export async function inviteUser(email: string) {
    return client.post(`/admin/invitations/${email}`);
}

export async function getInvitations() {
    return client.get<Invitation[]>(`/admin/invitations`);
}

export async function validateInvitation(invitationId: string) {
    return publicClient.get<void>(`/invitations/${invitationId}`);
}

export async function createInvitedUser(invitationId: string, email: string, username: string, password: string, firstName: string, lastName: string) {
    return publicClient.post(`/invitations/signup`,
        { "invitation_id": invitationId, "email": email, "username": username, "password": password, "first_name": firstName, "last_name": lastName }
    );
}

export async function createGuestUser() {
    const token = await getRecaptchaToken();
    return guestClient.post<void>("", null, { withCredentials: true, headers: { "Recaptcha-Token": token } });
}

export async function deleteGuestUser() {
    return guestClient.delete<void>("", { withCredentials: true });
}

export async function favoriteSession(sessionId: string) {
    return client.post(`/sessions/${sessionId}/favorite`, null);
}

export async function unfavoriteSession(sessionId: string) {
    return client.post(`/sessions/${sessionId}/unfavorite`, null);
}

export async function setInteractionSessionPublic(sessionId: string, interaction_id: string, isPublic: boolean) {
    return client.post(`/sessions/${sessionId}/interactions/${interaction_id}/session_public`, null, { params: { is_public: isPublic } });
}

export async function createPaymentSession() {
    return client.post<PaymentUrlResponse>(`/payment/session`, null);
}

export async function createInviteFromCheckoutSession(checkoutSessionId: string) {
    return client.post<InvitationIdResponse>(`/payment/invite`, null, { params: { checkout_session_id: checkoutSessionId } });
}

export async function joinWaitingList(email: string) {
    return client.post<SizeResponse>(`/waiting_list`, null, { params: { email } });
}