import { createListenerMiddleware } from "@reduxjs/toolkit"
import { addSessionAction, fetchAddInteractionMember, fetchAddMember, fetchDeleteInteraction, fetchLinkPublishedSession, fetchLinkSession, fetchPublishSession, fetchUploadFile, fetchUploadUrl, fetchQuery, fetchQueryNewInteraction, fetchAddInteractionEventFeedback } from "../features/session/sessionSlice"
import { enqueueSnackbar } from "../features/notifications/notificationsSlice";

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  actionCreator: fetchUploadUrl.rejected,
  effect: async (action, listenerApi) => {
    const { dispatch } = listenerApi;
    dispatch(
      enqueueSnackbar({
        message: "Failed to fetch upload url",
        options: {
          variant: "error",
        },
      })
    );
  }
});

listenerMiddleware.startListening({
  actionCreator: fetchDeleteInteraction.fulfilled,
  effect: async (action, listenerApi) => {
    const { dispatch } = listenerApi;
    dispatch(
      enqueueSnackbar({
        message: "Interaction deleted",
        options: {
          variant: "success",
        },
      })
    );
  }
});

listenerMiddleware.startListening({
  actionCreator: fetchDeleteInteraction.rejected,
  effect: async (action, listenerApi) => {
    const { dispatch } = listenerApi;
    dispatch(
      enqueueSnackbar({
        message: "Delete interaction failed",
        options: {
          variant: "error",
        },
      })
    );
  }
});

listenerMiddleware.startListening({
  actionCreator: fetchUploadFile.rejected,
  effect: async (action, listenerApi) => {
    const { dispatch } = listenerApi;
    dispatch(
      enqueueSnackbar({
        message: "Failed to fetch upload file",
        options: {
          variant: "error",
        },
      })
    );
  }
});

listenerMiddleware.startListening({
  actionCreator: fetchAddMember.rejected,
  effect: async (action, listenerApi) => {
    const { dispatch } = listenerApi;
    dispatch(
      enqueueSnackbar({
        message: "Failed to add user to Brua",
        options: {
          variant: "error",
        },
      })
    );
  }
});

listenerMiddleware.startListening({
  actionCreator: fetchAddInteractionMember.rejected,
  effect: async (action, listenerApi) => {
    const { dispatch } = listenerApi;
    dispatch(
      enqueueSnackbar({
        message: "Failed to add user",
        options: {
          variant: "error",
        },
      })
    );
  }
});

listenerMiddleware.startListening({
  actionCreator: fetchPublishSession.rejected,
  effect: async (action, listenerApi) => {
    const { dispatch } = listenerApi;
    dispatch(
      enqueueSnackbar({
        message: `Failed to publish Brua. ${action.error.message}`,
        options: {
          variant: "error",
        },
      })
    );
  }
});

listenerMiddleware.startListening({
  actionCreator: fetchPublishSession.fulfilled,
  effect: async (action, listenerApi) => {
    const { dispatch } = listenerApi;
    dispatch(
      enqueueSnackbar({
        message: `Brua ${action.payload.publishedId} publised successfully.`,
        options: {
          variant: "success",
        },
      })
    );
  }
});

listenerMiddleware.startListening({
  actionCreator: fetchLinkSession.fulfilled,
  effect: async (action, listenerApi) => {
    const { dispatch } = listenerApi;
    dispatch(
      enqueueSnackbar({
        message: `Brua linked successfully.`,
        options: {
          variant: "success",
        },
      })
    );
  }
});

listenerMiddleware.startListening({
  actionCreator: fetchLinkSession.rejected,
  effect: async (action, listenerApi) => {
    const { dispatch } = listenerApi;
    dispatch(
      enqueueSnackbar({
        // @ts-ignore
        message: action.payload.detail,
        options: {
          variant: "error",
        },
      })
    );
  }
});

listenerMiddleware.startListening({
  actionCreator: fetchLinkPublishedSession.fulfilled,
  effect: async (action, listenerApi) => {
    const { dispatch } = listenerApi;
    dispatch(
      enqueueSnackbar({
        message: `Brua linked successfully.`,
        options: {
          variant: "success",
        },
      })
    );
  }
});

listenerMiddleware.startListening({
  actionCreator: fetchLinkPublishedSession.rejected,
  effect: async (action, listenerApi) => {
    const { dispatch } = listenerApi;
    dispatch(
      enqueueSnackbar({
        message: `Brua linking failed.`,
        options: {
          variant: "error",
        },
      })
    );
  }
});

listenerMiddleware.startListening({
  actionCreator: fetchQueryNewInteraction.rejected,
  effect: async (action, listenerApi) => {
    const { dispatch } = listenerApi;
    dispatch(
      enqueueSnackbar({
        // @ts-ignore
        message: action.payload.detail,
        options: {
          variant: "error",
        },
      })
    );
  }
});

listenerMiddleware.startListening({
  actionCreator: fetchQuery.rejected,
  effect: async (action, listenerApi) => {
    const { dispatch } = listenerApi;
    dispatch(
      enqueueSnackbar({
        // @ts-ignore
        message: action.payload.detail,
        options: {
          variant: "error",
        },
      })
    );
  }
});

listenerMiddleware.startListening({
  actionCreator: fetchAddInteractionEventFeedback.fulfilled,
  effect: async (action, listenerApi) => {
    const { dispatch } = listenerApi;
    dispatch(
      enqueueSnackbar({
        // @ts-ignore
        message: "Thank you for your feedback!",
        options: {
          variant: "success",
        },
      })
    );
  }
});

listenerMiddleware.startListening({
  actionCreator: addSessionAction,
  effect: async (action, listenerApi) => {
    const action_type = action.payload.action.action_type
    const { dispatch } = listenerApi;

    switch (action_type) {
      case "AddContentAction":
        dispatch(
          enqueueSnackbar({
            message: "Content uploaded successfully",
            options: {
              variant: "success",
            },
          })
        );
        break;
      case "ProcessContentAction":
        dispatch(
          enqueueSnackbar({
            message: "Content ready",
            options: {
              variant: "success",
            },
          })
        );
        break;
      case "SummarizeContentAction":
        dispatch(
          enqueueSnackbar({
            message: "Content summarized successfully",
            options: {
              variant: "success",
            },
          })
        );
        break;
    }
  }
});

export const notificationsMiddleware = listenerMiddleware.middleware