import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchSession, fetchSessions, selectSessionById, selectSessionsLoadedState } from "./sessionSlice";
import { useAuth } from "../../auth";
import { useParams } from "react-router-dom";

export default function SessionsLoaderComponent() {
    const sessionsLoadedState = useAppSelector(selectSessionsLoadedState);
    const { sessionId } = useParams();
    const session = useAppSelector(selectSessionById(sessionId));
    const dispatch = useAppDispatch();
    const { isAuthenticated, isGuest } = useAuth();


    useEffect(() => {
        if (isAuthenticated && sessionsLoadedState === "idle")
            dispatch(fetchSessions());
    }, [dispatch, sessionsLoadedState, isAuthenticated]);

    useEffect(() => {
        if (sessionId && !session && (isAuthenticated || isGuest))
            dispatch(fetchSession(sessionId));
    }, [dispatch, sessionId, isGuest, isAuthenticated, session]);

    return null;
}