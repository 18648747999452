import { Box, Typography } from "@mui/material";
import { InternalPage } from "./internalPage";
import { useAppSelector } from "../app/hooks";
import { selectSessions } from "../features/session/sessionSlice";
import { Session } from "../types";
import { SessionCardLargeComponent, SessionCardSmallComponent } from "../components/cards/sessionCard";
import { HorizontalCarrouselComponent } from "../components/horizontalCarousel";
import { InteractionCardLargeComponent } from "../components/cards/interactionCard";
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useMemo } from "react";
import { getInteractionsSortedByEvents } from "../interactionUtils";
import { getSessionsSortedByLastUpdated } from "../sessionUtils";

const SectionTitle = ({ children }: { children: string }) => {
    return <Typography variant="h2" className="home-page-section-title">{children}</Typography>
}


const JumpBackInComponent = ({ sessions }: { sessions?: Session[] }) => {

    const { user } = useAuthenticator((context) => [context.user]);
    const userId = user?.username

    const sortedInteractionsList = useMemo(() => getInteractionsSortedByEvents(sessions, userId), [sessions, userId])
    const someInteractions = sortedInteractionsList?.slice(0, 8)

    return <Box className="home-section">
        <SectionTitle>Jump back in</SectionTitle>
        <Box className="spacing-medium" />
        <HorizontalCarrouselComponent className="home-page-carousel">
            {
                someInteractions ?
                    someInteractions.map(({ session, interaction }) => <InteractionCardLargeComponent className="recent-interaction-carousel-item" key={interaction.id} interaction={interaction} session={session} />)
                    : <></>
            }
        </HorizontalCarrouselComponent>
    </Box>
}

const RecentlyUpdatedSessionsComponent = ({ sessions }: { sessions?: Session[] }) => {

    if (!sessions) {
        return <></>
    }

    return <Box className="home-section">
        <SectionTitle>Recently updated</SectionTitle>
        <Box className="spacing-medium" />
        <HorizontalCarrouselComponent className="home-page-carousel">
            {sessions?.map((session, idx) => <SessionCardLargeComponent key={session.id} session={session} linkToPublished />)}
        </HorizontalCarrouselComponent>
    </Box>
}

const PrivateSessionsComponent = ({ sessions }: { sessions?: Session[] }) => {
    return <Box className="home-private-sessions">
        <SectionTitle>Your Spaces</SectionTitle>
        <Box className="spacing-medium" />
        <Box className="home-session-items">
            {sessions?.map((session, idx) => <SessionCardSmallComponent key={session.id} session={session} />)}
        </Box>
    </Box>
}

const FavoriteSessionsComponent = ({ sessions }: { sessions?: Session[] }) => {

    const hasFavoriteSessions = sessions?.length || -1 > 0;

    return <Box className="home-community-sessions">
        <SectionTitle>Favorite spaces</SectionTitle>
        <Box className="spacing-medium" />
        <Box className="home-session-items">
            {sessions?.map((session, idx) => <SessionCardSmallComponent key={session.id} session={session} />)}
            {!hasFavoriteSessions && <Typography variant="body1" color="text.secondary">You haven't favorited any spaces yet</Typography>}
        </Box>
    </Box>
}

const AllSessionsComponent = ({ sessions }: { sessions?: Session[] }) => {
    const nonFavoriteSessions = useMemo(() => sessions?.filter((session) => !session.is_favorite && !session.is_hub_session), [sessions]);
    const favoriteSessions = useMemo(() => sessions?.filter((session) => session.is_favorite), [sessions]);

    return <Box className="home-all-sessions home-section">
        <FavoriteSessionsComponent sessions={favoriteSessions} />
        <PrivateSessionsComponent sessions={nonFavoriteSessions} />
    </Box>

}

const HomePageBody = () => {

    const sessions = useAppSelector(selectSessions);
    const sortedSessions = useMemo(() => getSessionsSortedByLastUpdated(sessions), [sessions])

    return <Box className="internal-page-center">
        <Box className="home-page-body hide-scrollbar">
            <JumpBackInComponent sessions={sessions} />
            <RecentlyUpdatedSessionsComponent sessions={sortedSessions.slice(0, 8)} />
            <AllSessionsComponent sessions={sortedSessions} />
        </Box>
    </Box>
}

export const HomePage = () => {
    return <InternalPage>
        <HomePageBody />
    </InternalPage>
};