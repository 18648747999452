import { Box } from "@mui/material";
import { InternalPage } from "./internalPage";
import { ResultsComponent, SearchEverythingSearchBar, onInteractionNavigate, onSessionNavigate } from "../features/common/searchEverythingComponent";
import React, { useEffect } from "react";
import { useAppSelector } from "../app/hooks";
import { selectSessions } from "../features/session/sessionSlice";
import { useSearchEverything } from "../features/search";
import { useNavigate, useSearchParams } from "react-router-dom";


const SearchPageBody = () => {

    const sessions = useAppSelector(selectSessions)
    const { query, setQuery, searchResults } = useSearchEverything(sessions)
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const onSessionClicked = onSessionNavigate(navigate);
    const onInteractionClicked = onInteractionNavigate(navigate);

    const q = searchParams.get('q') || ''


    useEffect(() => {
        setQuery(q)
    }, [q, setQuery])

    const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            setSearchParams({ q: query })
        }
    }


    const { sessions: sessionResults, interactions: interactionResults } = searchResults

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value)
    }

    return <Box className="internal-page-center">
        <Box sx={{ flex: '1 1 auto', padding: '16px', paddingTop: '24px', display: 'flex', flexDirection: 'column', height: '100%'}}>
            <SearchEverythingSearchBar onChange={onChange} value={query} onKeyDown={onEnter} autoFocus />
            <ResultsComponent sessionResults={sessionResults} interactionResults={interactionResults} onInteractionClicked={onInteractionClicked} onSessionClicked={onSessionClicked} />
        </Box>
    </Box>
}

export const SearchPage = () => {
    return <InternalPage>
        <SearchPageBody />
    </InternalPage>
};