import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Session } from "../types";

export const getMostPublicSessionUrl = (session: Session) => {
    if (session.published_session) {
        return `/p/${session.published_session.id}`
    }
    return `/${session.id}`
}

export const useSessionUrl = () => {
    const { sessionId, username, sessionName } = useParams();

    return sessionId ? `/${sessionId}` : (username && sessionName) ? `/p/${username}/${sessionName}` : undefined;
}

export const useSourcesUrl = () => {

    const baseUrl = useSessionUrl();

    const sourcesUrl = useMemo(() => {
        return (sourceId?: string) => {
            if (sourceId) {
                return `${baseUrl}/sources/${sourceId}`
            } else {
                return `${baseUrl}/sources`;
            }
        }
    }, [baseUrl])

    return sourcesUrl;
}

export const useInteractionUrl = () => {

    const baseUrl = useSessionUrl();

    const interactionUrl = useMemo(() => {
        return (interactionId: string, sessionId: string) => {
            if (baseUrl) {
                return `${baseUrl}/${interactionId}`
            }
            return `/${sessionId}/${interactionId}`
        }
    }, [baseUrl])

    return interactionUrl;
}