import { AvatarProps, Box, Card, CardActionArea, Typography } from "@mui/material"
import { Interaction, Session } from "../../types"
import { MembersAvatarGroup, getInteractionMembers } from "../../members";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link } from "react-router-dom";
import { useInteractionUrl } from "../../hooks/useUrl";
import { Bubble } from "../bubble";
import { dateTimeStringToReadableTimeSince } from "../../time";
import LinesEllipsis from 'react-lines-ellipsis'
import { useAuth } from "../../auth";

const getContent = (interaction: Interaction) => {
    if (interaction.type === "TEXT_GENERATION") {
        const contentRefRegex = /(\s*\[CONTENT_REF\]\([a-f0-9-_]*\)\s*)/g;
        let lastResponse = "";
        for (let i = 0; i < interaction.events.length; i++) {
            const event = interaction.events[i];
            if (event.response) {
                lastResponse = event.response;
            }
        }

        return lastResponse.replace(contentRefRegex, "");

    } else {
        for (let i = 0; i < interaction.events.length; i++) {
            const event = interaction.events[i];
            if (event.query) {
                return event.query;
            }
        }
    }
}

const InteractionIcon = ({ interaction, props = {} }: { interaction: Interaction, props?: Record<string, string> }) => {
    if (interaction.type === "TEXT_GENERATION") {
        return <TextSnippetIcon {...props} />
    } else {
        return <QuestionAnswerIcon {...props} />
    }
}

const InteractionMembersAvatar = ({ interaction, session, props }: { interaction: Interaction, session: Session, props?: AvatarProps }) => {
    const { user } = useAuth();
    const members = interaction.members || [];
    const publicAvatar = <Bubble text="Public" icon={<PublicOutlinedIcon fontSize="tiny" />} noBorder />
    const personalAvatar = <Bubble text="Only you" icon={<LockOutlinedIcon fontSize="tiny" />} noBorder />

    const isPublic = interaction.is_session_public;
    const isPersonal = user && !isPublic && members.length === 1 && members[0] === user.id;

    if (isPersonal) {
        return personalAvatar;
    } else if (isPublic) {
        return publicAvatar;
    }

    const interactionMembers = getInteractionMembers(interaction, session.members)


    return <MembersAvatarGroup members={interactionMembers} max={3} avatarProps={props} />
}

export const InteractionCardLargeComponent = ({ interaction, session, className }: { interaction: Interaction, session: Session, className?: string }) => {
    const avatarProps = { sx: { width: '30px', height: '30px', fontSize: "0.7em" } }

    const interactionUrl = useInteractionUrl();

    const lastEvent = interaction.events[interaction.events.length - 1];
    

    return <Card className={className}>
        <CardActionArea component={Link} to={interactionUrl(interaction.id, session.id)}>
            <Box className="interaction-card">
                <Box className="interaction-card-header">
                    <Bubble icon={<InteractionIcon interaction={interaction} props={{ fontSize: "tiny" }} />}
                        text={interaction.type === "TEXT_GENERATION" ? "Text generation" : "Question answering"}
                    />
                </Box>
                <Box className="interaction-card-body">
                    <Typography variant="h3" noWrap>{interaction.name}</Typography>
                    <Typography variant="body1" color="text.secondary" component="div">
                        <LinesEllipsis
                            text={getContent(interaction)}
                            maxLine='2'
                            ellipsis='...'
                            trimRight
                            basedOn='letters'
                        />
                    </Typography>
                </Box>
                <Box className="interaction-card-header">
                    <InteractionMembersAvatar interaction={interaction} session={session} props={avatarProps} />
                    {
                        lastEvent &&
                        <Typography variant="body2" color="text.secondary">Edited {dateTimeStringToReadableTimeSince(lastEvent.datetime)} ago</Typography>
                    }
                </Box>
            </Box>
        </CardActionArea>
    </Card>
}

export const InteractionCardSmallComponent = () => {
    return <Box className="interaction-card" sx={{ height: '200px', width: '300px' }}>Interaction Card</Box>
}