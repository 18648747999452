import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import sessionReducer from '../features/session/sessionSlice';
import notificationsReducer from '../features/notifications/notificationsSlice';
import publishedSessionsReducer from '../features/session/publishedSessionSlice';
import { createLogger } from 'redux-logger'
import { getSessionSocketMiddleware } from '../middleware/sessionSocket';
import { getInteractionSocketMiddleware } from '../middleware/interactionSocket';
import { Socket } from '../socket';
import { sessionActionMiddleware } from '../middleware/sessionAction';
import { notificationsMiddleware } from '../middleware/notifications';

const logger = createLogger({
  predicate: (getState, action) => (action.type !== 'session/AddInteractionEvent') && action.payload?.event?.event_type !== "TextResponseEventChunk"
})

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    notifications: notificationsReducer,
    publishedSessions: publishedSessionsReducer
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => {
    
    if (process.env.NODE_ENV !== "production") {
      return getDefaultMiddleware()
        .concat(getSessionSocketMiddleware(new Socket()))
        .concat(getInteractionSocketMiddleware(new Socket()))
        .concat(sessionActionMiddleware)
        .concat(notificationsMiddleware)
        .concat(logger)
      }
      
      return getDefaultMiddleware()
      .concat(getSessionSocketMiddleware(new Socket()))
      .concat(getInteractionSocketMiddleware(new Socket()))
      .concat(sessionActionMiddleware)
      .concat(notificationsMiddleware)
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
