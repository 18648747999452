import { Box } from "@mui/material";
import React from "react";
import AuthPage from "./auth";
import { Logo } from "../logo";
import { stripePublishableKey } from "../App";

export const PremiumPage = () => {
    return <AuthPage>
        <Box className="auth-action-panel-header">
            <Logo />
        </Box>

        <Box className="auth-error-wrapper">
            {/* 
         @ts-ignore*/}
            <stripe-pricing-table pricing-table-id="prctbl_1PNVes2L0xj3jLeTjrAZrzlr"
                publishable-key={stripePublishableKey}>
                {/*
                     @ts-ignore*/}
            </stripe-pricing-table>
        </Box>
    </AuthPage>
}