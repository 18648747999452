import { Session } from "./types";

const DEFAULT_IMAGE = "https://img.freepik.com/free-photo/abstract-multi-colored-wave-pattern-shiny-flowing-modern-generated-by-ai_188544-15588.jpg?w=2000&t=st=1706799174~exp=1706799774~hmac=98e1e070a9de89653f330e15ed3713723637fc206ea5896a3fe025d74ef8b866";


export const getSessionLastUpdated = (session: Session) => {
    const lastContentAdded = [...session.contents].sort((a, b) => b.created_time.localeCompare(a.created_time))[0]?.created_time;
    const lastInteraction = [...session.interactions].sort((a, b) => b.created_time.localeCompare(a.created_time))[0]?.created_time;
    const createdTime = session.created_time;

    const lastUpdated = [lastContentAdded, lastInteraction, createdTime].sort((a, b) => b.localeCompare(a))[0];
    return lastUpdated;
}

export const getSessionsSortedByLastUpdated = (sessions: Session[]) => {
    const sessionsWithLastUpdated = sessions.map((session) => ({ session, lastUpdated: getSessionLastUpdated(session) }));
    sessionsWithLastUpdated.sort((a, b) => b.lastUpdated.localeCompare(a.lastUpdated));
    return sessionsWithLastUpdated.map((sessionWithLastUpdated) => sessionWithLastUpdated.session);

}

export const userIsSessionAdmin = (session: Session, userId?: string) => {
    return session.members.find(member => member.user.id === userId)?.role === "ADMIN";
}

const uniqueElements = (arr: any[]) => {
    return arr.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });
};

const getUniqueImages = (session: Session): string[] => {
    const sortedContent = [...session.contents].sort((a, b) => a.created_time.localeCompare(b.created_time));
    const sortedContentImages = sortedContent.map(content => content.tags.image).filter(image => image !== undefined);
    const uniqueImages = uniqueElements(sortedContentImages);
    return uniqueImages;
}

export const getSessionImage = (session: Session): string => {
    const uniqueImages = getUniqueImages(session);
    return uniqueImages.length > 0 ? uniqueImages[0] : DEFAULT_IMAGE;
}