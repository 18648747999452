import { useEffect } from 'react';
import { useAppDispatch } from '../app/hooks';


export const InteractionSocketConnector = ({ interactionId, sessionId }: { interactionId?: string, sessionId?: string }) => {
    const dispatch = useAppDispatch();

    useEffect(() => {

        const disconnect = () => {
            if (interactionId) {
                dispatch({ type: 'interactionSocket/disconnect' });
            }
        }

        if (interactionId && sessionId) {
            dispatch({ type: 'interactionSocket/connect', sessionId: sessionId, interactionId: interactionId })
        }

        return () => {
            disconnect();
        }
    }, [interactionId, sessionId, dispatch]);

    return <></>

}