import { useEffect } from "react";
import { useAppDispatch } from "../app/hooks";
import { socketConnect, socketDisconnect } from "../features/session/sessionSlice";


export function SessionSocketConnector({ sessionId, sessionIsLoaded }: { sessionId: string, sessionIsLoaded: boolean }) {

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (sessionIsLoaded)
            dispatch(socketConnect({ sessionId: sessionId }))

        return () => {
            dispatch(socketDisconnect());
        }
    }, [dispatch, sessionId, sessionIsLoaded]);

    return <></>
}