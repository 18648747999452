export class SocketConfig {
  protocol: string
  domain: string
  path: string
  token?: string

  constructor(protocol: string, domain: string, path: string, token?: string) {
    this.protocol = protocol
    this.domain = domain
    this.path = path
    this.token = token
  }

  get url() {
    const _url = `${this.protocol}://${this.domain}${this.path}`
    if (this.token) {
      return `${_url}?token=${this.token}`
    }
    return _url
  }
}

class Socket {
  socket: WebSocket | null
  _id: string | null
  shouldReconnect: boolean
  _getConfig: (() => Promise<SocketConfig>)
  _onMessage = (message: any) => { }
  watchDog: NodeJS.Timeout | null

  constructor() {
    this.socket = null;
    this._id = null;
    this.shouldReconnect = false;
    this._getConfig = () => Promise.resolve(new SocketConfig('', '', '', ''));
    this._onMessage = (message: any) => { };
    this.watchDog = null;
  }

  async connect(getConfig: () => Promise<SocketConfig>, id: string, onMessage: (message: any) => void) {
    if (id !== this._id) {
      this.disconnect()
    }
    this.shouldReconnect = true;
    this._getConfig = getConfig
    this._onMessage = onMessage
    this.watchDog = setInterval(() => { this.reconnect() }, 5000)

    if (id !== this._id) {
      await this.internalConnect()
    } else {
      // Should already be connected
    }
  }

  async reconnect() {
    if (this.shouldReconnect && !this.isNotClosed()) {
      await this.internalConnect(true)
    }
  }

  async internalConnect(isReconnect: boolean = false) {
    const config = await this._getConfig()
    this.socket = new WebSocket(config.url);
    this.socket.addEventListener('close', this.__onClose);
    this.socket.addEventListener('message', this._onMessage);
  }

  disconnect() {
    this.shouldReconnect = false

    if (this.socket) {
      this.socket.close();
    }

    this.socket = null;
    this._id = null;
    this._getConfig = () => Promise.resolve(new SocketConfig('', '', '', ''))
    this._onMessage = (message: any) => { }
    clearInterval(this.watchDog!);
    this.watchDog = null;
  }

  send(message: string) {
    if (this.socket) {
      this.socket.send(JSON.stringify(message))
    }
  }

  async __onClose(event: CloseEvent): Promise<void> {
    if (this.shouldReconnect) {
      await this.internalConnect()
    }
  }

  isNotClosed() {
    if (this.socket) {
      return this.socket.readyState !== WebSocket.CLOSED
    }
    return false
  }
}

export { Socket }