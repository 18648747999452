import { Box, Button, IconButton, TextField, Typography, CircularProgress } from "@mui/material"
import AuthPage from "./auth";
import { Logo } from "../logo";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import MuiLink from '@mui/material/Link';
import { Link } from 'react-router-dom';

import { useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { createPaymentSession, joinWaitingList } from "../api";

const schema = yup.object({
    email: yup.string().email().required(),
}).required();


const SignupPageBody = () => {
    const { getValues, register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit"
    });

    const [loading, setLoading] = useState(false)
    const [signedUp, setSignedUp] = useState(false)
    const [waitingListSize, setWaitingListSize] = useState(0)

    const resetAll = () => {
        reset();
        setLoading(false);
        setSignedUp(false);
    }

    const onJoinWaitinglist = async () => {
        setLoading(true);
        const email = getValues("email");
        const response = await joinWaitingList(email)
        await new Promise(r => setTimeout(r, 1000));
        const size = response?.data?.size;
        setWaitingListSize(size);
        setLoading(false);
        setSignedUp(true);
        setTimeout(resetAll, 5000)
    }

    const onSubscribe = async () => {
        const response = await createPaymentSession();
        const url = response?.data?.url;
        if (url) {
            // window.open(url, '_blank')?.focus();
            window.open(url);
        }
    }

    <Button onClick={onSubscribe}>Subscribe</Button>

    const successText = `You're #${waitingListSize} on the waiting list!`

    const joinWaitinglistButton = <IconButton type="submit" form="join-waiting-list-form"><KeyboardReturnIcon /></IconButton>
    const emailTextField = <TextField error={!!errors.email} placeholder="Enter email" {...register("email")}
        helperText={errors.email?.message || ""} fullWidth InputProps={{
            endAdornment: joinWaitinglistButton
        }} ></TextField>
    const successTextField = <TextField placeholder={successText} fullWidth disabled
        InputProps={{
            endAdornment: <CheckOutlinedIcon color="success" />
        }}
    ></TextField>

    return <>
        <Box className="auth-action-panel-header">
            <Logo />
        </Box>

        <Box className="auth-error-wrapper auth-action-panel-content">
            <Typography variant="h1">Join our waiting list</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "16px" }}>
                <form id="join-waiting-list-form" style={{ width: "100%" }} className="modal-form" onSubmit={handleSubmit(onJoinWaitinglist)} >
                    {loading ? <CircularProgress sx={{ margin: "auto" }} /> : signedUp ? successTextField : emailTextField}
                </form>
                <Typography variant="h3" sx={{ padding: "24px" }}>or</Typography>
                <Box>
                    <Typography variant="h2" sx={{ padding: "8px", textAlign: "center" }}>Skip the line by purchasing</Typography>
                    <Typography variant="h2" sx={{ padding: "8px", textAlign: "center" }}>
                        <MuiLink component={Link} to="/premium" color="primary" >a premium membership
                        </MuiLink>
                    </Typography>
                </Box>
                <Box className="spacing-large" />
                <Typography variant="h1" sx={{ padding: "16px" }}>250 NOK/ month</Typography>
                <Button color="contrast" variant="contained" size="large" fullWidth onClick={onSubscribe}>Get access now</Button>
            </Box>
        </Box>
    </>
}

export const SignupPage = () => {
    return <AuthPage>
        <SignupPageBody />
    </AuthPage>
}

