import { Divider, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Modal, ModalActions, ModalButton, ModalContents, ModalFormField, ModalHeader } from "./modal";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLastCreatedSessionId, setLastCreatedSessionId } from "../../features/session/sessionSlice";
import { UseModalProps } from "../../hooks/useModal";

export const CreateSessionRedirector = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const lastCreatedSession = useAppSelector(selectLastCreatedSessionId)
    useEffect(() => {
        if (lastCreatedSession) {
            dispatch(setLastCreatedSessionId(undefined))
            navigate(`/${lastCreatedSession}`);
        }
    }, [lastCreatedSession, dispatch, navigate]);

    return <></>
}

export const CreateSessionModalContext = React.createContext<UseModalProps | undefined>(undefined);

type FormData = {
    name: string;
    description: string;
};

export type { FormData as CreateSessionFormData };

export const CreateSessionModal = ({ open, onClose, onAccept }: { open: boolean, onClose: () => void; onAccept: (name: string, description?: string) => void; }) => {
    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            name: '',
            description: ''
        }
    });

    const nameInputRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (open) {
            setTimeout(() => nameInputRef.current?.focus(), 10);
        }
    }, [open]);

    const onSubmit = (data: FormData) => {
        onAccept(data.name, data.description);
        reset();
    }

    const doOnClose = () => {
        reset();
        onClose();
    }

    return <Modal open={open} onClose={onClose}>
        <ModalContents>
            <ModalHeader>
                Create Space
            </ModalHeader>
            <form onSubmit={handleSubmit(onSubmit)} className="modal-form" id="create-session-modal-form">
                <ModalFormField title="Name">
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => <TextField {...field} fullWidth placeholder="Name" inputRef={nameInputRef} />}
                    />
                </ModalFormField>
                <ModalFormField title="Description (optional)">
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) => <TextField {...field} fullWidth multiline rows={3} />}
                    />
                </ModalFormField>
            </form>
        </ModalContents>
        <Divider />
        <ModalActions>
            <ModalButton onClick={doOnClose} variant="text" color="primary" size="large">Close</ModalButton>
            <ModalButton form="create-session-modal-form" type="submit" variant="contained" color="contrast" size="large">Create</ModalButton>
        </ModalActions>
    </Modal>;
};
