import { Box, Card, CardActionArea, MenuItem, TextField, Typography } from "@mui/material"
import { InternalPage } from "./internalPage"
import { HorizontalCarrouselComponent } from "../components/horizontalCarousel"
import { useAppSelector } from "../app/hooks"
import { selectSessions } from "../features/session/sessionSlice"
import { PublicSessionCardSmallComponent, SessionCardLargeComponent } from "../components/cards/sessionCard"
import { useMemo } from "react"
import { selectHubSessionIds } from "../features/session/publishedSessionSlice"
import HubSessionsLoaderComponent from "../features/session/hubSessionsLoaderComponent"

const TopicCard = ({ topic }: { topic: string }) => {
    return <Card sx={{ padding: '0' }}>
        <CardActionArea sx={{ padding: '32px 20px' }}>
            <Typography variant="h4" sx={{ textAlign: 'center' }}>{topic}</Typography>
        </CardActionArea>
    </Card>
}

const HubPageBody = () => {
    const enableTopics = false;

    const topics = ["Space", "Sports", "Politics", "Science", "Design", "Architecture", "Environment", "Economics", "Entertainment", "Food", "Code", "Music"]
    const allSessions = useAppSelector(selectSessions);
    const hubSessionIds = useAppSelector(selectHubSessionIds);
    const hubSessions = useMemo(() => allSessions.filter((session) => hubSessionIds.includes(session.id)), [allSessions, hubSessionIds]);
    return <Box className="internal-page-center">
        <Box className="hub-page-body hide-scrollbar">
            <Box className="hub-page-header hub-page-padded-section">
                <Typography variant="h1" textAlign="center">Welcome to the Brua Community!</Typography>
                <Box className="spacing-large" />
                <Typography className="hub-page-header-subtitle" variant="h4" color="text.secondary">Stand on the shoulders of giants and explore our vast selection of shared Bruas on all sorts of topics</Typography>
            </Box>
            {
                enableTopics &&
                <Box className="hub-topics-grid hub-page-padded-section">
                    {topics.map((topic) => <TopicCard key={topic} topic={topic} />)}
                </Box>
            }
            <Box>
                <Typography variant="h2" className="hub-page-section-title">Trending</Typography>
                <Box className="spacing-medium" />
                <HorizontalCarrouselComponent className="hub-page-carousel">
                    {hubSessions?.slice(0, 6).map((session, idx) => <SessionCardLargeComponent key={session.id} session={session} linkToPublished />)}
                </HorizontalCarrouselComponent>
            </Box>
            <Box>
                <Typography variant="h2" className="hub-page-section-title">Most liked</Typography>
                <Box className="spacing-medium" />
                <HorizontalCarrouselComponent className="hub-page-carousel" >
                    {hubSessions?.slice(6, 12).map((session, idx) => <SessionCardLargeComponent key={session.id} session={session} linkToPublished />)}
                </HorizontalCarrouselComponent>
            </Box>
            <Box className="hub-page-padded-section">
                <Typography variant="h2">Explore more</Typography>
                <Box className="spacing-medium" />
                <Box sx={{ display: 'flex', gap: '16px' }}>
                    <TextField disabled select defaultValue="All categories" size="small">
                        <MenuItem value="All categories">All categories</MenuItem>
                        {topics.map((topic) => <MenuItem key={topic} value={topic}>{topic}</MenuItem>)}
                    </TextField>
                    <TextField disabled select defaultValue="Popular" size="small">
                        <MenuItem value="Popular">Popular</MenuItem>
                        <MenuItem value="Recent">Recent</MenuItem>
                        <MenuItem value="Trending">Trending</MenuItem>
                    </TextField>
                </Box>
                <Box className="spacing-medium" />
                <Box className="hub-page-explore-more-grid">
                    {hubSessions?.map((session, idx) => <PublicSessionCardSmallComponent key={session.id} session={session} />)}
                </Box>
            </Box>
        </Box>
    </Box>
}

export const HubPage = () => {

    return <InternalPage>
        <HubSessionsLoaderComponent />
        <HubPageBody />
    </InternalPage>
}