import { Box, CircularProgress, Divider, Typography, } from "@mui/material"
import { InternalPage } from "./internalPage"
import { useSession } from "../hooks/useSession";
import { Content, Session } from "../types";
import { SourceCardLarge } from "../components/cards/sourceCard";
import { DrawerContentSourceDetails, DrawerLinkedSessionSourceDetails } from "../components/sourcesPageDrawer";
import { SessionPageFooter, SessionPageHeader } from "./session";
import { useNavigate, useParams } from "react-router-dom";
import { useSourcesUrl } from "../hooks/useUrl";
import { LinkedSessionCardLarge } from "../components/cards/linkedSessionCard";
import { SessionSocketConnector } from "../connectors/sessionSocketConnector";
import { useAppSelector } from "../app/hooks";
import { selectSession } from "../features/session/sessionSlice";


const SourcesGrid = ({ session, onContentClick, onLinkedSessionClick}: { session: Session, onContentClick: (content: Content) => void, onLinkedSessionClick: (session: Session) => void }) => {
    return <Box className="sources-grid-container hide-scrollbar">
        <Box className="sources-grid hide-scrollbar">
            {session.contents.map((content) => <SourceCardLarge key={content.id} content={content} onClick={onContentClick} n_references={session.statistics.references_per_content[content.id] || 0} />)}
        </Box>
        {
            session.linked_sessions.length > 0 &&
            <Box>
                <Typography variant="h2">Linked spaces</Typography>
                <Box className="spacing-medium" />
                <Box className="sources-grid hide-scrollbar">
                    {session.linked_sessions.map((linkedSession) => <LinkedSessionCardLarge key={linkedSession} sessionId={linkedSession} onClick={onLinkedSessionClick} />)}
                </Box>
            </Box>
        }
    </Box>
}

const SourcesPageBody = () => {

    const { session, state } = useSession();

    const { sourceId } = useParams();
    const navigate = useNavigate();

    const sourcesUrl = useSourcesUrl();

    const candiateSelectedLinkedSession = useAppSelector(selectSession(sourceId));
    const selectedLinkedSession = session?.linked_sessions.includes(candiateSelectedLinkedSession?.id || "") ? candiateSelectedLinkedSession : undefined;
    const selectedContent = session?.contents.find(content => content.id === sourceId);

    const hasSelectedSource = selectedLinkedSession || selectedContent;

    const onContentClick = (content: Content) => {
        navigate(sourcesUrl(content.id))
    }

    const onLinkedSessionClick = (session: Session) => {
        navigate(sourcesUrl(session.id))
    }

    const onExit = () => {
        navigate(sourcesUrl())
    }

    if (state === 'loading') {
        return <CircularProgress />
    }

    let drawerSourceDetails = <></>;
    if (session) {
        if (selectedLinkedSession) {
            drawerSourceDetails = <DrawerLinkedSessionSourceDetails linkedSession={selectedLinkedSession} session={session} onExit={onExit} />
        } else if (selectedContent) {
            drawerSourceDetails = <DrawerContentSourceDetails content={selectedContent} onExit={onExit} session={session} />
        }
    }

    return <>
        {session?.id && <SessionSocketConnector sessionId={session?.id} sessionIsLoaded={!!session} />}
        <Box className={`internal-page-center ${hasSelectedSource ? 'desktop-only' : ''}`}>
            {session && <SessionPageHeader session={session} />}
            <Divider className="mobile-divider" />
            {session && <SourcesGrid session={session} onContentClick={onContentClick} onLinkedSessionClick={onLinkedSessionClick} />}
            <Divider />
            {session && <SessionPageFooter session={session} emptyOnly />}
        </Box>
        {
            session && hasSelectedSource &&
            <Box className="hide-scrollbar mobile-only-flex sources-page-mobile-content">
                {drawerSourceDetails}
            </Box >
        }
        <Divider orientation="vertical" className="desktop-divider" />
        {
            session && hasSelectedSource &&
            <Box className="internal-page-drawer sources-page-drawer hide-scrollbar">
                {drawerSourceDetails}
            </Box>
        }
    </>
}

export const SourcesPage = () => {
    return <InternalPage>
        <SourcesPageBody />
    </InternalPage>
}