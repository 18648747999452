import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { SessionLoaderComponent } from "../../features/session/sessionLoaderComponent";
import { selectSessionById } from "../../features/session/sessionSlice";
import { SourceCardLargeComponent, SourceCardSmallComponent, SourceCardTinyComponent } from "./sourceCard";
import { getSessionImage } from "../../sessionUtils";
import { Session } from "../../types";
import { useSourcesUrl } from "../../hooks/useUrl";

export const LinkedSessionCardSmall = ({ sessionId }: { sessionId: string }) => {

    const session = useAppSelector(selectSessionById(sessionId));
    const origin = window.location.origin

    const sessionUrl = session?.published_session ? `/p/${session?.published_session?.id}` : `/${sessionId}`;


    const icon = ""
    const source = `${origin}/#${sessionUrl}`
    const image = session ? getSessionImage(session) : ""
    const isCurrentReference = false
    const title = session?.published_session?.id || session?.name as string;

    const sourceUrl = useSourcesUrl();
    const linkTarget = sourceUrl(sessionId)

    return <>
        <SessionLoaderComponent sessionId={sessionId} />
        <SourceCardSmallComponent icon={icon} source={source} image={image} linkTarget={linkTarget} isCurrentReference={isCurrentReference} title={title} />
    </>
}

export const LinkedSessionCardTiny = ({ sessionId }: { sessionId: string }) => {

    const session = useAppSelector(selectSessionById(sessionId));
    const origin = window.location.origin
    const sessionUrl = session?.published_session ? `/p/${session?.published_session?.id}` : `/${sessionId}`;


    const icon = ""
    const source = `${origin}/#${sessionUrl}`
    const title = session?.published_session?.id || session?.name as string;

    const sourceUrl = useSourcesUrl();
    const linkTarget = sourceUrl(sessionId)

    return <>
        <SessionLoaderComponent sessionId={sessionId} />
        <SourceCardTinyComponent icon={icon} source={source} linkTarget={linkTarget} title={title} />
    </>
}

export const LinkedSessionCardLarge = ({ sessionId, onClick}: { sessionId: string, onClick: (session: Session) => void}) => {

    const navigate = useNavigate();

    const session = useAppSelector(selectSessionById(sessionId));
    const origin = window.location.origin
    const sessionUrl = session?.published_session ? `/p/${session?.published_session?.id}` : `/${sessionId}`;

    const icon = ""
    const source = `${origin}/#${sessionUrl}`
    const image = session ? getSessionImage(session) : ""
    const title = session?.published_session?.id || session?.name as string;
    const description = session?.published_session?.description || "";
    const n_references = session ? Object.values(session.statistics.references_per_content).reduce((a, b) => a + b, 0) : 0

    const doclick = () => {
        navigate(sessionUrl)
    }
    const onClickk = () => {
        if (session) {
            onClick(session)
        }
    }


    return <>
        <SessionLoaderComponent sessionId={sessionId} />
        <SourceCardLargeComponent icon={icon} source={source} image={image} title={title} description={description} onClick={onClickk} n_references={n_references} />
    </>
}