import { useEffect, useRef, useState } from "react";
import { AvatarProps, Button, Card, Chip, ClickAwayListener, Collapse, Divider, IconButton, LinearProgress, List, ListItemButton, ListItemText, TextField } from "@mui/material";
import { InteractionEvent, Member } from "../../types";
import { useAppDispatch } from '../../app/hooks';
import { ContentReference, fetchAddInteractionEventFeedback, setCurrentContentReferences } from "../../features/session/sessionSlice";
import { ListItem } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import { Box } from "@mui/material";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { getMemberInfoAvatar, getMemberInfoById } from "../../members";
import { Avatar, Typography } from "@mui/material";
import { datetimeStringToHourReadable } from "../../time";
import { useAuth } from "../../auth";
import { CollapsibleList } from "../collapsibleList";

const CONTENT_CHUNK_SEPARATOR = "_"

export type OnEventClicked = (event: InteractionEvent) => void;

export function QaEventsContainer({ events, members: _members, onClick, sessionId, interactionId }: { events: InteractionEvent[], members: Member[], onClick?: OnEventClicked, sessionId: string, interactionId: string }) {
    const { user, loading } = useAuth();
    const eventsEndRef = useRef<null | HTMLLIElement>(null);

    useEffect(() => {
        if (!loading) {
            scrollToBottom();
        }
    }, [events, loading]);

    if (loading) {
        return <></>
    }

    const thisMember = { role: "VISITOR", user: user } as Member;

    const members = [..._members, thisMember];

    const scrollToBottom = () => {
        eventsEndRef.current?.scrollIntoView({ behavior: "auto" });
    };

    const questions = events.filter((event) => event.event_type === "QueryEvent");
    const qaPairs = questions.map((question) => {
        const answer = events.find((event) => event.event_type === "TextResponseEvent" && event.query_id === question.id);
        const tools = events.filter((event) => event.event_type === "ToolResponseEvent" && event.query_id === question.id);

        return { question, answer, tools };
    })

    const numQaPairs = qaPairs.length;

    return <List className="interaction-container-events hide-scrollbar">
        {qaPairs.map(({ question, answer, tools }, index) => {
            const empty = !answer?.response && tools.length === 0;
            const isGenerating = tools.some((tool) => tool.is_generating) || answer?.is_generating || false;
            return <ListItem key={index} ref={index === numQaPairs - 1 ? eventsEndRef : undefined}>
                <Card className="qa-events-container-card no-hover">
                    <QuestionHeader question={question} members={members} />
                    <Divider />
                    {
                        tools.map((tool, index) => <ToolComponent tool={tool} />)
                    }
                    {
                        answer?.response &&
                        <InteractionEventTextComponent text={answer?.response || "No response"} />
                    }
                    {empty && <LinearProgress />}
                    <QuestionFooter question={question} answerEmpty={empty} isGenerating={isGenerating} sessionId={sessionId} interactionId={interactionId} />
                </Card>
            </ListItem>
        })}
    </List>
}

const ToolComponent = ({ tool }: { tool: InteractionEvent }) => {
    const toolName = tool.tool_name === "summarize_document" ? "Generated summary" : "Tool";

    return <Box className="tool-component">
        <CollapsibleList title={toolName}>
            <Box className="tool-component-text">
                <Markdown remarkPlugins={[remarkGfm]} children={tool.response}></Markdown>
            </Box>
        </CollapsibleList>
    </Box>
}

const HeaderAvatar = ({ question, members }: { question: InteractionEvent, members: Member[] }) => {
    const avatarProps = { sx: { width: '24px', height: '24px', fontSize: "0.7em" } };

    const memberInfo = getMemberInfoById(question.user_id, members);
    const avatar = memberInfo ? getMemberInfoAvatar(memberInfo, avatarProps) : <UnknownMemberAvatar props={avatarProps} />;
    const displayName = memberInfo ? memberInfo.displayName : "Unknown";
    return <Box sx={{ display: 'flex', gap: '8px' }}>
        {avatar} <Typography variant="body2" color="text.secondary"> {displayName}</Typography>
    </Box>
}

const QuestionFeedback = ({ open, onClose, submitFeedback }: { open: boolean, onClose: () => void, submitFeedback: ((feedback: string[]) => void) }) => {

    const defaultFeedback = ["Incorrect information", "Malformed output", "Missing sources"]

    const [feedbackState, setFeedbackState] = useState<Set<string>>(new Set());
    const [otherFeedback, setOtherFeedback] = useState<string>("");

    const reset = () => {
        setFeedbackState(new Set());
        setOtherFeedback("");
    }

    const onSubmit = () => {

        const feedback = Array.from(feedbackState);
        if (otherFeedback) {
            feedback.push(otherFeedback);
        }

        if (feedback.length === 0) {
            return;
        }

        submitFeedback(feedback);

        reset();
        onClose();
    }

    return <Collapse in={open}>
        <Box sx={{ display: "flex", gap: "8px", flexDirection: "column", paddingTop: "8px", alignItems: "start" }}>
            <Box sx={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                {
                    defaultFeedback.map((feedback) => {
                        const checked = feedbackState.has(feedback);
                        return <Chip key={feedback} variant={checked ? "filled" : "outlined"} label={feedback} onClick={() => {
                            const newFeedbackState = new Set(feedbackState);
                            if (checked) {
                                newFeedbackState.delete(feedback);
                            } else {
                                newFeedbackState.add(feedback);
                            }
                            setFeedbackState(newFeedbackState);
                        }} />
                    })

                }
            </Box>
            <Box sx={{ width: "100%" }}>
                <Typography variant="body1">Other feedback (optional)</Typography>
                <TextField fullWidth value={otherFeedback} onChange={(e) => setOtherFeedback(e.target.value)} />
            </Box>
            <Button sx={{ alignSelf: "end" }} variant="contained" color="contrast" onClick={onSubmit}>Submit</Button>
        </Box>
    </Collapse>

}

const QuestionFooter = ({ question, answerEmpty, isGenerating, sessionId, interactionId }: { question: InteractionEvent, answerEmpty: boolean, isGenerating: boolean, sessionId: string, interactionId: string }) => {
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const { user } = useAuth();
    const dispatch = useAppDispatch();

    const isAuthor = question.user_id === user?.id;
    const isRegenerateImplented = false;
    const isShareImplemented = false;
    const allActions = <>
        {isRegenerateImplented && isAuthor && <IconButton color="inherit" size="small" onClick={() => { }}><RefreshOutlinedIcon fontSize="small" color="inherit" /></IconButton>}
        {isShareImplemented && <IconButton color="inherit" size="small" onClick={() => { }}><ShareOutlinedIcon fontSize="small" color="inherit" /></IconButton>}
        <IconButton color="inherit" size="small" onClick={() => setFeedbackOpen(!feedbackOpen)}><ThumbDownAltOutlinedIcon fontSize="small" color="inherit" /></IconButton>
    </>

    const stopIsImplemented = false;
    const generatingActions = <>
        {stopIsImplemented && <IconButton size="small" onClick={() => { }}><StopCircleOutlinedIcon fontSize="large" /></IconButton>}
    </>
    const alignSelf = isGenerating ? "center" : "end";

    const submitFeedback = (feedback: string[]) => {
        if (!answerEmpty) {
            dispatch(fetchAddInteractionEventFeedback({ sessionId, interactionId: interactionId, eventId: question.id, feedback }))
        }
    }


    return <ClickAwayListener onClickAway={() => setFeedbackOpen(false)}>
        <Box sx={{ display: 'flex', flexDirection: "column" }}>
            <Box sx={{ display: 'flex', gap: '8px', alignSelf: alignSelf }} >
                <Typography color="text.secondary">
                    {isGenerating ? generatingActions : !answerEmpty && allActions}
                </Typography>
            </Box>
            <QuestionFeedback open={feedbackOpen} onClose={() => setFeedbackOpen(false)} submitFeedback={submitFeedback} />
        </Box>
    </ClickAwayListener>
}

const QuestionHeader = ({ question, members }: { question: InteractionEvent, members: Member[] }) => {

    return <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Box sx={{ display: 'flex', gap: '8px', justifyContent: "space-between" }}>
            <HeaderAvatar question={question} members={members} />
        </Box>
        <Typography variant="h3" >{question.query}</Typography>
        <Typography variant="body2" color="text.secondary">{datetimeStringToHourReadable(question.datetime)}</Typography>
    </Box>
}

export const InteractionEventTextComponent = ({ text, hideRefs = false }: { text: string, hideRefs?: boolean }) => {
    const dispatch = useAppDispatch();

    const onRefClickMouseOver = (contentReference: ContentReference) => {
        dispatch(setCurrentContentReferences([contentReference]));
    };

    return <Markdown remarkPlugins={[remarkGfm]} className='markdown-body' components={{
        a(props) {
            const { children, href, node, ...rest } = props;
            if (children === "CONTENT_REF") {
                if (hideRefs) {
                    return <></>;
                }
                const _href = href ? href : "";
                const [contentId, chunkId] = _href.split(CONTENT_CHUNK_SEPARATOR);
                const contentReference = { contentId, chunkId };
                return <span content-id={contentId} chunk-id={chunkId} className="content-reference" onClick={() => onRefClickMouseOver(contentReference)}><InfoOutlinedIcon /></span>;
            } else {
                return <a href={href} {...rest}>{children}</a>;
            }
        }
    }} children={text}></Markdown>;
};

export const InteractionEventWithAvatarComponent = ({ avatar, text }: { avatar: React.ReactNode, text: string }) => {
    return <Box sx={{ position: 'relative' }}>
        <Box>
            {avatar}
        </Box>
        <Box>
            {<InteractionEventTextComponent text={text} />}
        </Box>
    </Box>;

};

const UnknownMemberAvatar = ({ props }: { props: AvatarProps }) => {
    return <Avatar {...props}>U</Avatar>;
}

