import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchSession, selectSessionById } from "./sessionSlice";

export const SessionLoaderComponent = ({ sessionId }: { sessionId: string }) => {
    const session = useAppSelector(selectSessionById(sessionId));
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!session)
            dispatch(fetchSession(sessionId));
    }, [dispatch, session, sessionId]);

    return null;
}