import { Avatar, AvatarGroup, Box, Checkbox, Divider, FormControlLabel, IconButton, List, ListItem, MenuItem, SxProps, TextField, Typography } from "@mui/material";
import { Modal, ModalActions, ModalButton, ModalContents, ModalFormField, ModalHeader } from "./modal";
import { Member, MemberRole, Session, defaultPublishedSessionSettings } from "../../types";
import { getMemberInfo, getMemberInfoAvatar, getRoleLabel, sortMembers } from "../../members";
import React, { useEffect } from "react";

import CloseIcon from '@mui/icons-material/Close';
import KeyIcon from '@mui/icons-material/Key';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { UrlTextField } from "../urlTextField";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch } from "../../app/hooks";
import { fetchAddMember, fetchPublishSession, fetchUpdateSession } from "../../features/session/sessionSlice";

const MemberItem = ({ member, isCreator, disabled }: { member: Member, isCreator?: boolean, disabled?: boolean }) => {

    const [role, setRole] = React.useState<MemberRole>(member.role);

    useEffect(() => {
        setRole(member.role);
    }, [member.role]);

    const memberInfo = getMemberInfo(member);
    const avatar = getMemberInfoAvatar(memberInfo, {});

    const onChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.value === "REMOVE") {
            // Remove member
            return;
        }
        setRole(event.target.value as MemberRole);
    }

    const _role = isCreator ? "CREATOR" : role;

    const changeRoleImplemented = false
    const changeRoleDisabled = !changeRoleImplemented || (isCreator || disabled);

    return <ListItem>
        <Box className="share-session-member-item">
            <Box className="share-session-member-item-header">
                {avatar}
                <Box className="share-session-member-item-header-names">
                    <Typography variant="h3">{memberInfo.displayName}</Typography>
                    <Typography variant="body1" color="text.secondary">{member.user.username}</Typography>
                </Box>
            </Box>
            <Box className="share-session-member-item-role">
                <TextField disabled={changeRoleDisabled} label="" select onChange={onChangeRole} size="small" value={_role}>
                    {isCreator ? <MenuItem value={"CREATOR"}>{"Creator"}</MenuItem> : null}
                    <MenuItem value={"ADMIN"}>{getRoleLabel("ADMIN")}</MenuItem>
                    <MenuItem value={"CONTRIBUTOR"}>{getRoleLabel("CONTRIBUTOR")}</MenuItem>
                    <MenuItem value={"VIEWER"}>{getRoleLabel("VIEWER")}</MenuItem>
                    <Divider />
                    <MenuItem value={"REMOVE"}>Remove access</MenuItem>
                </TextField>
            </Box>
        </Box>
    </ListItem>
}

type AccessLevels = "INVITE" | "LINK" | "PUBLIC";
type Screen = "MAIN" | "INVITE_MEMBERS" | "CURRENT_MEMBERS" | "PUBLISH";

export const ShareSessionModal = ({ open, onClose, onAccept, session }: { open: boolean, onClose: () => void; onAccept: (name: string) => void, session: Session }) => {
    const [screen, setScreen] = React.useState<Screen>("MAIN");
    const [email, setEmail] = React.useState<string>("");

    const onEmailSelected = (email: string) => {
        setEmail(email);
        setScreen("INVITE_MEMBERS");
    }

    const doOnClose = () => {
        setEmail("");
        setScreen("MAIN");
        onClose();
    }

    const goToMain = () => {
        setEmail("");
        setScreen("MAIN");
    }

    const goToMembers = () => {
        setScreen("CURRENT_MEMBERS");
    }

    const goToPublish = () => {
        setScreen("PUBLISH");
    }

    const onCloseClick = () => {
        switch (screen) {
            case "MAIN":
                onClose();
                break;
            case "CURRENT_MEMBERS":
            case "INVITE_MEMBERS":
            case "PUBLISH":
                setScreen("MAIN");
                break;
        }
    }

    const getScreen = () => {
        switch (screen) {
            case "MAIN":
                return <MainModalScreen onClose={doOnClose} onAccept={onEmailSelected} session={session} onMembersClicked={goToMembers} onPublishClicked={goToPublish} />
            case "INVITE_MEMBERS":
                return <InviteMembersScreen email={email} onCancel={goToMain} sessionId={session.id} />
            case "CURRENT_MEMBERS":
                return <MembersModalScreen session={session} onClose={goToMain} />
            case "PUBLISH":
                return <PublishScreen session={session} onClose={goToMain} />
        }
    }

    return <Modal open={open} onClose={doOnClose} >
        <Box className="modal-close-icon">
            <IconButton onClick={onCloseClick}>
                <CloseIcon />
            </IconButton>
        </Box>
        {
            getScreen()
        }
    </Modal>
}

const inviteMembersScreenSchema = yup.object({
    email: yup.string().email().required(),
    role: yup.mixed<MemberRole>().oneOf(["ADMIN", "CONTRIBUTOR", "VIEWER"]).required(),
    notify: yup.boolean(),
    message: yup.string().max(256).optional(),
}).required();

const InviteMembersScreen = ({ email, onCancel, sessionId }: { email: string, onCancel: () => void, sessionId: string }) => {
    const dispatch = useAppDispatch();
    const { control, register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: { email: email, role: "CONTRIBUTOR", notify: false, message: "" },
        resolver: yupResolver(inviteMembersScreenSchema),
        mode: "onSubmit"
    });

    const doInvite = (data: yup.InferType<typeof inviteMembersScreenSchema>) => {
        dispatch(fetchAddMember({ userEmail: data.email, role: data.role, sessionId: sessionId }));
        onCancel();
    }

    return <ModalContents>
        <ModalHeader>
            Invite to space
        </ModalHeader>
        <form id="invite" className="modal-form" onSubmit={handleSubmit(doInvite)}>
            <Box sx={{ display: 'flex', width: '100%', gap: "16px" }}>
                <TextField disabled sx={{ flex: 1 }} {...register("email")} placeholder="Add email" helperText={errors.email?.message || ""} error={!!errors.email} label="" />
                <Controller
                    name="role"
                    control={control}
                    render={({ field }) => {
                        return <TextField {...field} label="" select>
                            <MenuItem value={"ADMIN"}>{getRoleLabel("ADMIN")}</MenuItem>
                            <MenuItem value={"CONTRIBUTOR"}>{getRoleLabel("CONTRIBUTOR")}</MenuItem>
                            <MenuItem value={"VIEWER"}>{getRoleLabel("VIEWER")}</MenuItem>
                        </TextField>
                    }} />
            </Box>
            <FormControlLabel control={
                <Controller
                    name="notify"
                    control={control}
                    render={({ field }) => {
                        const { value, ...rest } = field;
                        return <Checkbox disabled {...rest} checked={value} />
                    }} />
            } label="Notify by email" />
            <ModalFormField title="Message">
                <TextField disabled {...register("message")} fullWidth multiline rows={4} />
            </ModalFormField>
        </form>
        <ModalActions>
            <ModalButton onClick={onCancel} variant="text" color="primary" size="large">Back</ModalButton>
            <ModalButton form="invite" type="submit" variant="contained" color="primary" size="large">Invite</ModalButton>
        </ModalActions>
    </ModalContents>
}

const nameErrorMessage = "Name can only contain letters, numbers, and - _";

const publishScreenSchema = yup.object({
    name: yup.string().matches(/^[-\w_]+$/, { message: nameErrorMessage }).min(1).max(64).required(),
    description: yup.string().min(6).max(256).required(),
    addContentMinRole: yup.mixed<MemberRole>().oneOf(["ADMIN", "CONTRIBUTOR", "VIEWER", "VISITOR"]).required(),
    shareInteractionMinRole: yup.mixed<MemberRole>().oneOf(["ADMIN", "CONTRIBUTOR", "VIEWER", "VISITOR"]).required(),
}).required();

const PublishScreen = ({ session, onClose }: { session: Session, onClose: () => void }) => {
    const { user } = useAuthenticator(context => [context.user]);
    const { control, register, handleSubmit, reset, formState: { errors }, watch } = useForm({
        defaultValues: { addContentMinRole: defaultPublishedSessionSettings.add_content_min_role, shareInteractionMinRole: defaultPublishedSessionSettings.share_interaction_min_role },
        resolver: yupResolver(publishScreenSchema),
        mode: "onChange"
    });


    const dispatch = useAppDispatch();

    const doClose = () => {
        reset();
        onClose();
    }

    const doPublish = (data: yup.InferType<typeof publishScreenSchema>) => {
        const settings = {
            add_content_min_role: data.addContentMinRole,
            share_interaction_min_role: data.shareInteractionMinRole
        };
        dispatch(fetchPublishSession({ sessionId: session.id, name: data.name, description: data.description, settings: settings }));
        doClose();
    }

    const getRoleLabel = (role: MemberRole) => {
        switch (role) {
            case "ADMIN":
                return "Admin";
            case "CONTRIBUTOR":
                return "Contributor";
            case "VIEWER":
                return "Viewer";
        }
    }
    return <>
        <ModalContents>
            <ModalHeader>Publish</ModalHeader>
            <form id="publish-session-form" className="modal-form" onSubmit={handleSubmit(doPublish)} >
                <ModalFormField title="Published name">
                    <TextField error={!!errors.name} variant="outlined" label=""
                        type="text"
                        {...register("name")}
                        helperText={errors.name?.message || ""}
                        fullWidth
                    />
                </ModalFormField>
                <ModalFormField title="Description">
                    <TextField error={!!errors.description} variant="outlined" label=""
                        multiline
                        rows={4}
                        type="text"
                        {...register("description")}
                        helperText={errors.description?.message || ""}
                        fullWidth
                    />
                </ModalFormField>
                <Box sx={{display: 'flex', width: '100%', alignItems: 'center', gap: '16px'}}>
                    <ModalFormField title="Who can edit?">
                        <Controller
                            name="addContentMinRole"
                            control={control}
                            render={({ field }) => {
                                return <TextField {...field} label="" select fullWidth>
                                    <MenuItem value={"ADMIN"}>{getRoleLabel("ADMIN")}</MenuItem>
                                    <MenuItem value={"CONTRIBUTOR"}>{getRoleLabel("CONTRIBUTOR")}</MenuItem>
                                    <MenuItem value={"VIEWER"}>{getRoleLabel("VIEWER")}</MenuItem>
                                </TextField>
                            }}
                        />
                    </ModalFormField>
                    <ModalFormField title="Who can invite?">
                        <Controller
                            name="shareInteractionMinRole"
                            control={control}
                            render={({ field }) => {
                                return <TextField {...field} label="" select fullWidth>
                                    <MenuItem value={"ADMIN"}>{getRoleLabel("ADMIN")}</MenuItem>
                                    <MenuItem value={"CONTRIBUTOR"}>{getRoleLabel("CONTRIBUTOR")}</MenuItem>
                                    <MenuItem value={"VIEWER"}>{getRoleLabel("VIEWER")}</MenuItem>
                                </TextField>
                            }}
                        />
                    </ModalFormField>
                </Box>
                <ModalFormField title="URL">
                    <UrlTextField prefix={`alpha.brua.io/#/p/${user.attributes?.nickname}`} value={watch("name")} />
                </ModalFormField>
            </form>
        </ModalContents>
        <Divider />
        <ModalActions>
            <ModalButton onClick={doClose} variant="text" color="primary" size="large">Cancel</ModalButton>
            <ModalButton form="publish-session-form" type="submit" variant="contained" color="contrast" size="large">Publish</ModalButton>
        </ModalActions>
    </>
}

const ModalRow = ({ children, onClick }: { children: React.ReactNode, onClick?: () => void }) => {
    let sx = { display: 'flex', alignItems: 'center', gap: '8px' } as SxProps;

    if (onClick) {
        sx = { ...sx, cursor: "pointer" };
    }

    return <Box sx={sx} onClick={onClick}>{children}</Box>
}

const GeneralAccessLevel = ({ session, disabled }: { session: Session, disabled?: boolean }) => {
    const isPublished = !!session.published_session

    const [accessLevel, setAccessLevel] = React.useState<AccessLevels>(isPublished ? "PUBLIC" : session.is_link_public ? "LINK" : "INVITE");

    const dispatch = useAppDispatch();

    const isDisabled = isPublished || disabled;

    const accessLevelItems = {
        "INVITE": {
            icon: <KeyIcon />,
            text: "Only invited people can access"
        },
        "LINK": {
            icon: <InsertLinkOutlinedIcon />,
            text: "Anyone with the link can access"
        },
        "PUBLIC": {
            icon: <LanguageOutlinedIcon />,
            text: "Anyone can access"
        }
    }

    const { icon, text } = accessLevelItems[accessLevel];

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const accessLevel = e.target.value as AccessLevels;
        dispatch(fetchUpdateSession({ sessionId: session.id, isLinkPublic: accessLevel === "LINK"}));
        setAccessLevel(accessLevel);
    }

    return <ModalRow>
        <Avatar>{icon}</Avatar>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <TextField disabled={isDisabled} value={accessLevel} label="" select variant="standard" InputProps={{ disableUnderline: true }} onChange={onChange}>
                <MenuItem value={"INVITE"}>Invite</MenuItem>
                <MenuItem value={"LINK"}>Link</MenuItem>
                <MenuItem disabled value={"PUBLIC"}>Public</MenuItem>
            </TextField>
            <Typography variant="body1" color="text.secondary">{text}</Typography>
        </Box>
    </ModalRow >
}

const MembersModalScreen = ({ session, onClose }: { session: Session, onClose: () => void }) => {
    const { user } = useAuthenticator(context => [context.user]);
    const userIdAdmin = session.members.find(member => member.user.id === user.username)?.role === "ADMIN";

    const sortedMembers = sortMembers(session.members, session.created_by);
    return <>
        <ModalContents>
            <ModalHeader>
                {session.members.length} members in this space
            </ModalHeader>
            <List>
                {sortedMembers.map((member) => <MemberItem disabled={!userIdAdmin} isCreator={member.user.id === session.created_by} member={member} key={member.user.id} />)}
            </List>
        </ModalContents>
        <Divider />
        <ModalActions>
            <ModalButton onClick={onClose} variant="text" color="primary" size="large">Back</ModalButton>
        </ModalActions>
    </>
}

const PeopleWithAccessField = ({ session, onClick }: { session: Session, onClick: () => void }) => {
    const numMembers = session.members.length;
    const sortedMembers = sortMembers(session.members, session.created_by);

    const subsetMemberInfo = sortedMembers.slice(0, 2).map((member) => getMemberInfo(member));
    const memberAvatars = subsetMemberInfo.map((memberInfo) => getMemberInfoAvatar(memberInfo));

    const Text = ({ children }: { children: string }) => {
        return <Typography sx={{ flex: 1 }} variant="body1" fontWeight="600">{children}</Typography>
    }

    return <ModalRow onClick={onClick}>
        <AvatarGroup>
            {memberAvatars}
        </AvatarGroup>
        {
            numMembers > 2 &&
            <Text>{`${subsetMemberInfo[0].displayName}, ${subsetMemberInfo[1].displayName} and ${numMembers - 2} more.`}</Text>
        }
        {
            numMembers === 2 &&
            <Text>{`${subsetMemberInfo[0].displayName} and ${subsetMemberInfo[1].displayName}`}</Text>
        }
        {
            numMembers === 1 &&
            <Text>{subsetMemberInfo[0].displayName}</Text>
        }
        <Typography variant="body1" color="text.secondary"><KeyboardArrowRightIcon /></Typography>
    </ModalRow>
}

const mainModalScreenSchema = yup.object({
    email: yup.string().email().required(),
}).required();

const MainModalScreen = ({ onClose, onAccept, session, onMembersClicked, onPublishClicked }: { onClose: () => void; onAccept: (email: string) => void, session: Session, onMembersClicked: () => void, onPublishClicked: () => void }) => {

    const { user } = useAuthenticator(context => [context.user]);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(mainModalScreenSchema),
        mode: "onSubmit"
    });

    const sessionIsPublished = !!session.published_session;

    const doInvite = (data: yup.InferType<typeof mainModalScreenSchema>) => {
        onAccept(data.email);
    }

    const userIsCreator = session.created_by === user.username;
    const userMember = session.members.find(member => member.user.id === user.username);
    const userIsAdmin = userMember?.role === "ADMIN";

    return <>
        <ModalContents>
            <ModalHeader>
                Share "{session.name}"
            </ModalHeader>
            <form onSubmit={handleSubmit(doInvite)}>
                <ModalFormField>
                    <TextField {...register("email")} fullWidth placeholder="Add email" helperText={errors.email?.message || ""} error={!!errors.email} label="" />
                </ModalFormField>
            </form>
            <ModalFormField title="General access level">
                <GeneralAccessLevel session={session} disabled={!userIsAdmin} />
            </ModalFormField>
            <ModalFormField title="Members">
                <PeopleWithAccessField session={session} onClick={onMembersClicked} />
            </ModalFormField>
            {
                !sessionIsPublished && userIsCreator &&
                <ModalFormField title="Publish">
                    <ModalRow onClick={onPublishClicked}>
                        <Avatar><LanguageOutlinedIcon /></Avatar>
                        <Typography variant="body1" fontWeight="600" sx={{ flex: 1 }}>Publish to community</Typography>
                        <Typography variant="body1" color="text.secondary"><KeyboardArrowRightIcon /></Typography>
                    </ModalRow>
                </ModalFormField>
            }
        </ModalContents >
        <Divider />
        <ModalActions>
            <ModalButton onClick={onClose} variant="text" color="primary" size="large">Close</ModalButton>
        </ModalActions>
    </>
};
