import { Box, Button, CircularProgress, Divider, IconButton, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { InternalPage, InternalPageContentBodyHeader } from "./internalPage";
import { useSession } from "../hooks/useSession";
import { Session } from "../types";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined';
import { InteractionCardLargeComponent } from "../components/cards/interactionCard";
import { SourceCardSmall, SourceCardTiny } from "../components/cards/sourceCard";
import { MembersAvatarGroup } from "../members";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { OutlinedButton } from "../components/outlinedButton";
import React, { useEffect, useMemo, useState } from "react";
import { FileInput } from "../features/fileUpload";
import { UploadLinkModal } from "../components/modals/uploadLinkModal";
import { useAppDispatch } from "../app/hooks";
import { fetchDeleteSession, fetchFavoriteSession, fetchQueryNewInteraction, fetchUpdateSession, fetchUploadFile } from "../features/session/sessionSlice";
import SendIcon from '@mui/icons-material/Send';
import { useModal } from "../hooks/useModal";
import { ConfirmModal, ConfirmModalBody, ConfirmModalStrong, deleteAcceptText, deleteTitle } from "../components/modals/confirmModal";
import { useInteractionUrl, useSessionUrl, useSourcesUrl } from "../hooks/useUrl";
import { HorizontalCarrouselComponent } from "../components/horizontalCarousel";
import { ShareSessionModal } from "../components/modals/shareSessionModal";
import { HeaderPublicBubble } from "../components/bubble";
import { SessionSocketConnector } from "../connectors/sessionSocketConnector";
import { useUploadUrl } from "../hooks/useUploadUrl";
import { LinkedSessionCardSmall, LinkedSessionCardTiny } from "../components/cards/linkedSessionCard";

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useAuth } from "../auth";
import { ModalFormField } from "../components/modals/modal";
import { usePopover } from "../hooks/usePopover";
import { ActionsPopover, ActionsPopoverItem } from "../components/popover/actionsPopover";
import { userIsSessionAdmin } from "../sessionUtils";
import { GuestErrorModal } from "../components/modals/guestErrorModal";
import { space_prompt_enabled } from "../App";

export const SessionPageHeaderActions = ({ session }: { session: Session }) => {
    const { isAuthenticated } = useAuth();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const popover = usePopover();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { user } = useAuth();
    const canEdit = userIsSessionAdmin(session, user?.id)

    const shareModal = useModal();
    const deleteModal = useModal();

    const sessionUrl = useSessionUrl();

    const isPublished = session.published_session !== null;

    const canDelete = !isPublished && canEdit;
    const canShare = isAuthenticated;
    const canFavorite = isAuthenticated;

    const isFavorite = session.is_favorite;


    const onDelete = () => {
        if (session.id) {
            dispatch(fetchDeleteSession(session.id));
            navigate("/");
        }
    }

    const shareModalProps = {
        open: shareModal.open,
        onClose: shareModal.onClose,
        session: session,
        onAccept: shareModal.onClose
    }

    const deleteSessionModalProps = {
        open: deleteModal.open,
        onClose: deleteModal.onClose,
        onAccept: onDelete,
        title: deleteTitle,
        icon: <DeleteOutlineIcon />,
        body: <ConfirmModalBody>Are you sure you want to delete the space <ConfirmModalStrong>{session.name}</ConfirmModalStrong>? This action cannot be reversed.</ConfirmModalBody>,
        acceptText: deleteAcceptText,
    }


    const popoverProps = {
        open: popover.open,
        anchorEl: popover.anchorEl,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        } as const,
        transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
        } as const
    }

    const onPopoverInfoClicked = () => {
        navigate(`${sessionUrl}/info`);
        popover.handleClose();
    }

    const onPopoverShareClicked = () => {
        shareModal.onClick();
        popover.handleClose();
    }

    const onPopoverDeleteClicked = () => {
        deleteModal.onClick();
        popover.handleClose();
    }

    const onFavoriteClicked = () => {
        const newFavorite = !isFavorite;
        dispatch(fetchFavoriteSession({ sessionId: session.id, shouldFavorite: newFavorite }));
    }

    return <>
        <ShareSessionModal {...shareModalProps} />
        <ConfirmModal {...deleteSessionModalProps} />
        <ActionsPopover {...popoverProps}>
            {canShare && <ActionsPopoverItem onClick={onPopoverShareClicked} text="Share" icon={<ShareOutlinedIcon />} />}
            <ActionsPopoverItem onClick={onPopoverInfoClicked} text="Info" icon={<InfoOutlinedIcon />} />
            {canFavorite && <ActionsPopoverItem onClick={onFavoriteClicked} text={isFavorite ? "Unfavorite" : "Favorite"} icon={isFavorite ? <HeartBrokenOutlinedIcon /> : <FavoriteBorderIcon />} />}
            {
                canDelete ?
                    <>
                        <Divider />
                        <ActionsPopoverItem onClick={onPopoverDeleteClicked} text="Delete" color="error" icon={<DeleteOutlineIcon color="error" />} />
                    </>
                    : <></>
            }
        </ActionsPopover >
        {
            isMobile ?
                <IconButton onClick={popover.handleClick} aria-label="more-actions" color="primary"><MoreVertIcon fontSize="small" /></IconButton>
                :
                <>
                    {canShare && <Button variant="contained" color="contrast" aria-label="share" size="small" onClick={shareModal.onClick}>Share</Button>}
                    <IconButton aria-label="information" component={Link} to={`${sessionUrl}/info`}><InfoOutlinedIcon /></IconButton>
                    {canFavorite && <IconButton aria-label="favorite" onClick={onFavoriteClicked}>{isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}</IconButton>}
                    {
                        canDelete ?
                            <IconButton aria-label="delete" color="error" onClick={deleteModal.onClick}><DeleteOutlineIcon fontSize="small" /></IconButton>
                            : <></>
                    }
                </>
        }
    </>
}


export const SessionPageHeader = ({ session }: { session: Session }) => {
    const isPublished = !!session.published_session;

    return <InternalPageContentBodyHeader >
        {
            isPublished ?
                <HeaderPublicBubble /> : <MembersAvatarGroup members={session.members} max={5} />
        }
        <SessionPageHeaderActions session={session} />
    </InternalPageContentBodyHeader >
}

const SessionInteractionsGrid = ({ session }: { session: Session }) => {
    const interactions = session?.interactions;
    return <Box className="session-interactions-grid hide-scrollbar">
        {interactions?.map((interaction, idx) => <InteractionCardLargeComponent key={interaction.id} interaction={interaction} session={session} />)}
    </Box>
}

interface SessionPageFooterProps {
    session: Session
    onAddFileClicked?: () => void
    onFileSelected: (file: File) => void
    onAddLink: () => void
}

const SessionPageFooterNoContent = ({ onFileSelected, onAddLink, onAddFileClicked }: SessionPageFooterProps) => {
    return <Box className="internal-page-footer internal-page-footer-no-content">
        <Button onClick={onAddFileClicked ? onAddFileClicked : () => { }} component="label" aria-label="add-file" color="paper" fullWidth variant="contained" startIcon={<AddCircleOutlineIcon />} >
            Upload file
            {!onAddFileClicked && <FileInput onSelected={onFileSelected} />}
        </Button>
        <Button onClick={onAddLink} aria-label="add-link" color="paper" fullWidth variant="contained" startIcon={<InsertLinkIcon />}>Upload link</Button>
    </Box>
}

const SessionPageTextField = ({ session }: { session: Session }) => {

    const [query, setQuery] = React.useState("");
    const queryIsValid = query !== "";

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const interactionUrl = useInteractionUrl();

    const onNewInteractionCreated = (interactionId: string) => {
        navigate(interactionUrl(interactionId, session.id));
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onSubmit(query);
        }
    }

    const onSubmit = (query: string) => {
        if (queryIsValid)
            dispatch(fetchQueryNewInteraction({ sessionId: session.id, query: query, onNewInteractionCreated: onNewInteractionCreated }))
        setQuery("");
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    }

    return <TextField
        label=""
        placeholder="Interact with your content..."
        variant="outlined"
        fullWidth
        onKeyDown={onKeyDown}
        value={query}
        onChange={onChange}
        InputProps={{
            endAdornment: <IconButton onClick={() => onSubmit(query)} disabled={!queryIsValid} color="primary"><SendIcon /></IconButton>
        }}
    />
}

const SessionPageFooterDefault = ({ session, onFileSelected, onAddLink, onAddFileClicked }: SessionPageFooterProps) => {
    return <Box className="internal-page-footer">
        <IconButton onClick={onAddFileClicked ? onAddFileClicked : () => { }} aria-label="add-file" color="primary" component="label">
            <AddCircleOutlineIcon />
            {!onAddFileClicked && <FileInput onSelected={onFileSelected} />}
        </IconButton>
        <IconButton onClick={onAddLink} aria-label="add-link" color="primary"><InsertLinkIcon /></IconButton>
        <SessionPageTextField session={session} />
    </Box>
}

export const SessionPageFooter = ({ session, emptyOnly }: { session: Session, emptyOnly?: boolean }) => {

    const dispatch = useAppDispatch();

    const { isGuest } = useAuth();

    const uploadLinkModal = useModal();
    const guestErrorModal = useModal();

    const uploadUrl = useUploadUrl();

    const handleCloseUploadLinkModal = () => {
        uploadLinkModal.onClose();
    }

    const doUploadLink = (url: string) => {
        uploadUrl(url, session.id);
        uploadLinkModal.onClose();
    }

    const hasContent = (session.contents.length + session.linked_sessions.length) > 0;

    const onFileSelected = (file: File) => {
        if (isGuest) {
            guestErrorModal.onClick();
            return;
        }

        dispatch(fetchUploadFile({ sessionId: session.id, file: file }))
    }

    const onAddLinkClicked = async () => {
        if (isGuest) {
            guestErrorModal.onClick();
            return;
        }
        uploadLinkModal.onClick();
    }

    const Component = hasContent && !emptyOnly ? SessionPageFooterDefault : SessionPageFooterNoContent;

    const onAddFileClicked = () => {
        if (isGuest) {
            guestErrorModal.onClick();
        }
    }

    return <>
        {isGuest && <GuestErrorModal open={guestErrorModal.open} onClose={guestErrorModal.onClose} onAccept={guestErrorModal.onClose} />}
        <Component onAddLink={onAddLinkClicked} onFileSelected={onFileSelected} session={session} onAddFileClicked={isGuest ? onAddFileClicked : undefined} />
        <UploadLinkModal open={uploadLinkModal.open} onClose={handleCloseUploadLinkModal} onAccept={doUploadLink} />
    </>
}

const SourcesList = ({ session }: { session: Session }) => {

    const hasLinkedSessions = session.linked_sessions.length > 0;

    return <Box className="sources-list">
        {session.contents.map((content) => <SourceCardSmall key={content.id} content={content} />)}
        {
            hasLinkedSessions && <Typography variant="body1" color="text.secondary">Linked spaces</Typography>
        }
        {session.linked_sessions.map((linkedSession) => <LinkedSessionCardSmall key={linkedSession} sessionId={linkedSession} />)}
    </Box>
}

const SessionPageMobileSources = ({ session }: { session: Session }) => {

    const [sourcesOpen, setSourcesOpen] = useState<boolean>(false);

    const toggleSources = () => {
        setSourcesOpen(!sourcesOpen)
    }

    const numSources = session.contents.length + session.linked_sessions.length;

    const buttonText = sourcesOpen ? "Hide sources" : `Based on ${numSources} sources`;

    return <Box className="session-page-sources-section mobile-only">
        <OutlinedButton size="small" onClick={toggleSources}><InsertLinkIcon />{buttonText}</OutlinedButton>
        {sourcesOpen &&
            <Box sx={{ paddingTop: "8px" }}>
                <HorizontalCarrouselComponent>
                    {session.contents.map((content) => <SourceCardTiny content={content} key={content.id} />)}
                    {session.linked_sessions.map((linkedSession) => <LinkedSessionCardTiny key={linkedSession} sessionId={linkedSession} />)}
                </HorizontalCarrouselComponent>
            </Box>
        }
    </Box>
}

type UpdateSessionFormParams = {
    name: string;
    description: string;
    basePrompt: string;
}

const SessionDescription = ({ session }: { session: Session }) => {
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [basePrompt, setBasePrompt] = useState<string>("");
    const [edit, setEdit] = useState<boolean>(false);

    const { user } = useAuth();
    const canEdit = useMemo(() => userIsSessionAdmin(session, user?.id), [session, user])
    const dispatch = useAppDispatch();

    useEffect(() => {
        setDescription(session.description || "");
    }, [session.description])

    useEffect(() => {
        setName(session.name);
    }, [session.name])

    useEffect(() => {
        setBasePrompt(session.base_prompt || "");
    }, [session.base_prompt])

    useEffect(() => {
        setEdit(false);
    }, [session.id])

    const sx = { display: 'flex', flexGrow: 1, width: "100%", height: "100%", overflowY: "auto" }

    const markdownComponent = <Box sx={{ width: "100%", height: "100%", overflowY: "auto" }} >
        <Markdown remarkPlugins={[remarkGfm]} className='markdown-body markdown-body-default session-info-markdown' children={description}></Markdown>
    </Box>
    const textAreaComponent = <ModalFormField title="Description" sx={{ height: "100%" }}>
        <TextField
            multiline
            fullWidth
            sx={sx}
            InputProps={{
                sx: {
                    height: '100%',
                    alignItems: 'start'
                }
            }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
        />
    </ModalFormField>
    const basePromptComponent = <ModalFormField title="Base prompt">
        <TextField
            multiline
            fullWidth
            sx={sx}
            InputProps={{
                sx: {
                    height: '100%',
                    alignItems: 'start'
                }
            }}
            value={basePrompt}
            onChange={(e) => setBasePrompt(e.target.value)}
            inputProps={{ maxLength: 512 }}
        />
    </ModalFormField>

    const toggleEdit = () => {
        if (!canEdit) return;

        if (edit) {
            let updateParams = {} as UpdateSessionFormParams;
            const nameIsValid = name !== session.name && name !== "";
            const descriptionIsValid = description !== session.description;
            const basePromptIsValid = basePrompt !== session.base_prompt;

            if (nameIsValid) {
                updateParams["name"] = name;
            }

            if (descriptionIsValid) {
                updateParams["description"] = description;
            }

            if (basePromptIsValid) {
                updateParams["basePrompt"] = basePrompt;
            }

            if (nameIsValid || descriptionIsValid || basePromptIsValid) {
                dispatch(fetchUpdateSession({ sessionId: session.id, ...updateParams }))
            }
        }
        setEdit(!edit);
    }

    return <Box className="session-description">
        {
            edit ?
                <ModalFormField title="Name">
                    <TextField value={name} onChange={(e) => setName(e.target.value)} fullWidth />
                </ModalFormField>
                :
                <Typography variant="h1">{name}</Typography>
        }
        {
            canEdit && <Button sx={{ position: "absolute", top: 0, right: "16px" }} onClick={toggleEdit} variant="contained">{edit ? "Save" : "Edit"}</Button>
        }
        {edit ? textAreaComponent : markdownComponent}
        {space_prompt_enabled && edit && basePromptComponent}
    </Box>
}

const SessionPageBody = () => {

    const { session, state } = useSession();
    const location = useLocation();
    const isInfo = location.pathname.endsWith("info");

    const sourcesUrl = useSourcesUrl();

    if (state === 'failed') {
        return <Typography variant="h1">Sorry, this space does not exist.</Typography>
    }

    if (state === 'loading') {
        return <CircularProgress />
    }

    const hasSources = session && ((session.contents?.length > 0) || (session.linked_sessions?.length > 0));
    const hasInteractions = session && (session?.interactions?.length > 0)
    const showPlaceholder = !isInfo && (!hasSources && !hasInteractions)
    const showInfo = isInfo || (!showPlaceholder && !hasInteractions);

    const showInteractionsGrid = !showInfo && !showPlaceholder;

    return <>
        {session?.id && <SessionSocketConnector sessionId={session?.id} sessionIsLoaded={!!session} />}
        <Box className="internal-page-center">
            {session && <SessionPageHeader session={session} />}
            {hasSources && <SessionPageMobileSources session={session} />}
            {showInteractionsGrid && session && <SessionInteractionsGrid session={session} />}
            {showInfo && session && <SessionDescription session={session} />}
            {showPlaceholder &&
                <Box className="session-placeholder">
                    <Typography color="text.secondary"> Add some sources </Typography>
                    <Typography color="text.secondary"> to interact with your Brua</Typography>
                </Box>
            }
            <Divider />
            {session && <SessionPageFooter session={session} />}
        </Box>
        <Divider orientation="vertical" />
        <Box className="internal-page-drawer hide-scrollbar">
            <Box className="internal-page-drawer-header">
                <Typography variant="h3">Sources</Typography>
                <Button size="small" variant="outlined" color="primary" component={Link} to={sourcesUrl()}>View all</Button>
            </Box>
            <Divider />
            <Box className="internal-page-drawer-body">
                {session && <SourcesList session={session} />}
            </Box>
        </Box>
    </>
}

export const SessionPage = () => {
    return <InternalPage>
        <SessionPageBody />
    </InternalPage>
};