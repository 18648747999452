import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { Content, Member, Session } from "../types";
import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import { DisplaySource, SourceMedia, getContentImage, getContentSource } from "./cards/sourceCard";
import { MemberInfo, getMemberInfo, getMemberInfoAvatar } from "../members";
import { dateToDatetimeReadableNoWeekday, datetimeStringToDate } from "../time";
import { Link, useNavigate } from "react-router-dom";
import { ConfirmModal, ConfirmModalBody, ConfirmModalStrong, deleteTitle } from "./modals/confirmModal";
import { useModal } from "../hooks/useModal";
import { useAppDispatch } from "../app/hooks";
import { fetchRemoveContent, fetchUnlinkSession, fetchUpdateContent } from "../features/session/sessionSlice";
import { isUrl } from "../urls";
import { getMostPublicSessionUrl } from "../hooks/useUrl";
import { useMemo } from "react";
import { getSessionImage } from "../sessionUtils";

const SourcesPageDrawerHeader = ({ onExit, onDelete, onPrimary, isPrimary, primaryEnabled }: { onExit: () => void, onDelete: () => void, onPrimary: () => void, isPrimary: boolean, primaryEnabled: boolean }) => {
    const navigate = useNavigate();

    return <Box className="internal-page-drawer-header">
        <Box>
            <IconButton onClick={onExit} className="desktop-only">
                <CloseIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={() => navigate(-1)} className="mobile-only-flex">
                <ArrowBackIosIcon fontSize="small" />
            </IconButton>
        </Box>
        <Box>
            <IconButton disabled>
                <EditOutlinedIcon fontSize="small" />
            </IconButton>
            <IconButton disabled>
                <ShareOutlinedIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={onPrimary} disabled={!primaryEnabled}>
                {isPrimary ? <StarOutlinedIcon fontSize="small" /> : <StarBorderOutlinedIcon fontSize="small" />}
            </IconButton>
            <IconButton onClick={onDelete}>
                <DeleteOutlinedIcon fontSize="small" color="error" />
            </IconButton>
        </Box>
    </Box>;
}

interface SourceDetails {
    source: string
    title: string
    description: string
    creator: MemberInfo
    addedTime: Date
    image: string
    isPrimary: boolean
    primaryEnabled: boolean
    author: string
    icon: string
    deleteActionText: string
}

export const DrawerContentSourceDetails = ({ content, onExit, session }: { content: Content; onExit: () => void, session: Session }) => {
    const dispatch = useAppDispatch()
    const source = content?.source

    if (!content) {
        return <></>;
    }

    const { title = content.name, description, author = "Unknown" } = content.tags

    const creatorMember = session.members.find(member => member.user.id === content.created_by) as Member
    const creatorMemberInfo = getMemberInfo(creatorMember)

    const deleteSource = () => {
        dispatch(fetchRemoveContent({ sessionId: session.id, contentId: content.id }))
    }

    const image = getContentImage(content)

    const onTogglePrimary = () => {
        dispatch(fetchUpdateContent({ sessionId: session.id, contentId: content.id, isPrimary: !content.is_primary }))
    }

    const sourceDetails = {
        source,
        title,
        description,
        creator: creatorMemberInfo,
        addedTime: datetimeStringToDate(content.created_time),
        image,
        isPrimary: content.is_primary,
        primaryEnabled: true,
        author,
        icon: getContentSource(content).icon,
        deleteActionText: "delete"
    }

    return <DrawerSourceDetails sourceDetails={sourceDetails} onExit={onExit} onDelete={deleteSource} onTogglePrimary={onTogglePrimary} />
}

export const DrawerLinkedSessionSourceDetails = ({ linkedSession, onExit, session }: { linkedSession: Session, onExit: () => void, session: Session }) => {
    const dispatch = useAppDispatch();

    const creatorMember = linkedSession.members.find(member => member.user.id === linkedSession.created_by) as Member
    const creatorMemberInfo = getMemberInfo(creatorMember)

    const linkedTime = useMemo(() => {
        const linkedActionsDate = session.log.filter(action => action.linked_session_id === linkedSession.id).map(action => datetimeStringToDate(action.datetime))
        const latestDate = new Date(Math.max(...linkedActionsDate.map(date => date.getTime())));
        return latestDate
    }, [session, linkedSession])

    const url = `${window.location.origin}/#${getMostPublicSessionUrl(linkedSession)}`
    console.log(url)

    const sourceDetails = {
        source: url,
        title: linkedSession.name,
        description: linkedSession.description?.slice(0, 100) || "",
        creator: creatorMemberInfo,
        addedTime: linkedTime,
        image: getSessionImage(linkedSession),
        isPrimary: false,
        primaryEnabled: false,
        author: creatorMemberInfo.displayName,
        icon: "/favicon.ico",
        deleteActionText: "unlink"
    }

    const unlinkSession = () => {
        dispatch(fetchUnlinkSession({ sessionId: session.id, linkedSessionId: linkedSession.id }))
    }

    return <DrawerSourceDetails sourceDetails={sourceDetails} onExit={onExit} onDelete={unlinkSession} onTogglePrimary={() => { }} />
}

export const DrawerSourceDetails = ({ sourceDetails, onExit, onDelete, onTogglePrimary }: { sourceDetails: SourceDetails; onExit: () => void, onDelete: () => void, onTogglePrimary: () => void }) => {
    const { source, title, creator, addedTime, author, image, isPrimary, primaryEnabled, icon, description, deleteActionText } = sourceDetails
    const deleteModal = useModal()
    const sourceIsUrl = source ? isUrl(source) : false

    const avatarWithName = <Box sx={{ display: 'flex', alignItems: 'center', gap: "12px" }}>
        {getMemberInfoAvatar(creator)}
        <Typography variant="body1" color="text.primary">{creator.displayName}</Typography>
    </Box>

    const tags = {
        'Added by': avatarWithName,
        'Uploaded': dateToDatetimeReadableNoWeekday(addedTime),
    }

    const confirmModalBody = <>
        <ConfirmModalBody>Are you sure you want to {deleteActionText} <ConfirmModalStrong>{title}</ConfirmModalStrong>? This action cannot be reversed.</ConfirmModalBody>
    </>

    const onAccept = () => {
        onDelete();
        onExit();
    }

    return <>
        <ConfirmModal open={deleteModal.open} onClose={deleteModal.onClose} onAccept={onAccept}
            title={deleteTitle} body={confirmModalBody} acceptText={`Yes, ${deleteActionText} it`} icon={<DeleteOutlinedIcon />} />

        <SourcesPageDrawerHeader onExit={onExit} onDelete={deleteModal.onClick} onPrimary={onTogglePrimary} isPrimary={isPrimary} primaryEnabled={primaryEnabled} />
        <Divider />
        <Box className="sources-page-drawer-body">
            <SourceMedia image={image} sx={{ height: '242px', objectFit: "cover" }} />
            <Box className="sources-page-drawer-body-header">
                <Typography variant="h2">{title}</Typography>
                <Box className="internal-page-drawer-body-subtitle">
                    <Typography variant="body1" color="text.secondary">By {author}</Typography>
                    <DisplaySource source={source} icon={icon} />
                </Box>
                <Typography variant="body1" color="text.secondary">{description}</Typography>
            </Box>
            <Box className="sources-page-drawer-body-metadata">
                {
                    Object.entries(tags).map(([key, value]) => {
                        return <Box key={key} className="sources-page-drawer-body-metadata-item">
                            <Typography variant="body1" color="text.secondary" textTransform="capitalize">{key}</Typography>
                            <Box className="spacing-medium" />
                            <Typography variant="h4">{value}</Typography>
                        </Box>
                    })
                }
            </Box>
        </Box>
        <Divider />
        <Box className="sources-page-drawer-footer">
            {
                sourceIsUrl ?
                    <Button variant="outlined" size="large" fullWidth startIcon={<VisibilityOutlinedIcon />} component={Link} target="_blank" to={source || ""} >
                        View source
                    </Button>
                    :
                    <Button disabled variant="outlined" size="large" fullWidth startIcon={<FileDownloadOutlinedIcon />} >Download source</Button>
            }
        </Box>
    </>;
};

