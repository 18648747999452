import React, { useState } from "react";
import { ListItemText, Collapse, ListItemButton } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

export const CollapsibleList = ({ children, title, defaultOpen, onClick}: { children: React.ReactNode, title: string, defaultOpen?: boolean, onClick?: (newState: boolean) => void }) => {
    const [isOpen, setIsOpen] = useState(!!defaultOpen);

    const handleClick = () => {
        const newState = !isOpen;
        setIsOpen(newState);
        if (onClick) {
            onClick(newState);
        }
    }

    return <><ListItemButton onClick={handleClick}>
        <ListItemText primary={title} />
        <KeyboardArrowDown
            sx={{
                transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
                transition: '0.2s',
            }}
        />
    </ListItemButton>
        <Collapse in={isOpen}>
            {children}
        </Collapse>
    </>
}